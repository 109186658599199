import React, { useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Button, Typography, DialogActions } from '@material-ui/core'
import { decode } from 'jsonwebtoken'
import { Loading, Password } from '.'
import { useMemdbRequest, useLogin } from '../hooks'

export function ResetPassword() {
  const [resetPasswordToken, setResetPasswordToken] = React.useState()
  const [isOpen, setOpen] = React.useState(false)
  const { request, isLoading, errorMessage: error } = useMemdbRequest()
  const { doLogin } = useLogin()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location.href)
      const token = url.searchParams.get('reset-password')
      if (token) {
        setResetPasswordToken(token)
        setOpen(true)
        url.searchParams.delete('reset-password')
        window.history.replaceState({}, document.title, url.toString())
      }
    }
  }, [])

  function handleToggleOpen() {
    setOpen(!isOpen)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement & { password: HTMLInputElement }>) {
    event.preventDefault()
    event.stopPropagation()

    if (resetPasswordToken) {
      const { password } = event.currentTarget
      const decodedToken = decode(resetPasswordToken)

      if (decodedToken && typeof decodedToken === 'object' && decodedToken.jti) {
        request({
          method: 'POST',
          url: `/member/${decodedToken.jti}`,
          headers: {
            Token: resetPasswordToken
          },
          data: {
            password: password.value,
            password2: password.value
          }
        }).then(() => {
          doLogin(decodedToken.sub, password.value).then(() => {
            handleToggleOpen()
          })
        })
      }
    }
  }

  return (
    <>
      <Button onClick={handleToggleOpen}>Glömt lösenord</Button>
      <Dialog open={isOpen} onClose={handleToggleOpen}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Återställ lösenord</DialogTitle>
          <DialogContent>
            <Typography>Fyll i ditt nya lösenord nedan och klicka sedan på skicka</Typography>
            <Password label="Nytt lösenord" autoFocus error={!!error} helperText={error} />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Skicka
            </Button>
          </DialogActions>
        </form>
        {isLoading && <Loading />}
      </Dialog>
    </>
  )
}
