import React from 'react'
import { Menu, MenuItem as MUIMUIMenuItem, ListItemText, ListItemSecondaryAction, Button } from '@material-ui/core'

import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import { ClassNames } from '@emotion/core'
import { MenuProps } from '@material-ui/core/Menu'
import { Link } from 'gatsby'

import { ConditionalWrapper } from '.'

export interface MenuItem<T extends {} = { children?: React.ReactNode }> {
  title?: string
  link?: string
  subMenu?: MenuItem[]
  Component?: React.ComponentType<T>
  componentProps?(props: { onCloseAll(): void }): T
  menuProps?: Partial<MenuProps>
}

export const CascadingMenu: React.FC<{ menu: MenuItem; onClose?(): void }> = React.forwardRef(({ menu, onClose }, ref) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

  function handleClick(event: React.MouseEvent) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleLinkClick() {
    handleClose()
    if (onClose) {
      onClose()
    }
  }

  const Component = menu.Component || MUIMUIMenuItem

  return (
    <div>
      <ConditionalWrapper
        condition={!!menu.link}
        wrapper={children => (
          <ClassNames>
            {({ css: classNamesCss }) => {
              const anchorCss = classNamesCss({ textDecoration: 'none', color: 'inherit' })
              const to = menu.link || ''
              return to.startsWith('http') ? (
                <a href={to} css={anchorCss}>
                  {children}
                </a>
              ) : (
                <Link to={to} css={anchorCss} onClick={handleLinkClick}>
                  {children}
                </Link>
              )
            }}
          </ClassNames>
        )}
      >
        <Component
          // eslint-disable-next-line react/no-children-prop
          children={
            Component === Button ? (
              <>
                {menu.title}
                <ArrowDropDown />
              </>
            ) : (
              <>
                <ListItemText>{menu.title}</ListItemText>
                {menu.subMenu && menu.subMenu.length && (
                  <ConditionalWrapper
                    condition={!!menu.link}
                    wrapper={children => <ListItemSecondaryAction onClick={handleClick}>{children}</ListItemSecondaryAction>}
                  >
                    <ArrowRight />
                  </ConditionalWrapper>
                )}
              </>
            )
          }
          {...(menu.subMenu && menu.subMenu.length && { 'aria-controls': 'menu', 'aria-haspopup': 'true', onClick: handleClick })}
          {...(menu.componentProps && menu.componentProps({ onCloseAll: handleLinkClick }))}
        />
      </ConditionalWrapper>

      {menu.subMenu && (
        <Menu
          ref={ref}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          getContentAnchorEl={null}
          {...menu.menuProps}
        >
          {menu.subMenu.map((item, index) => (
            <CascadingMenu key={index.toString()} menu={item} onClose={handleLinkClick} />
          ))}
        </Menu>
      )}
    </div>
  )
})
