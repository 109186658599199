import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'

import { OtherTournament } from '../lib/createPages'

export const OtherTournaments: React.FC<{ otherTournaments: OtherTournament[] }> = ({ otherTournaments }) => {
  return (
    <Grid item xs={12}>
      <Grid container css={{ border: `2px solid #C0175D`, borderRadius: 4 }}>
        <Grid item xs={12}>
          <Box m={2} fontSize="text.body1">
            <Typography variant="h6" color="primary">
              Andra roliga tävlingar
            </Typography>
          </Box>
        </Grid>
        {otherTournaments.map(node => (
          <Grid item xs={12} sm={6} key={node.wordpress_id}>
            <Box m={2}>
              <Typography color="primary" css={{ fontWeight: 500 }}>
                {node.title}
              </Typography>
              {/* eslint-disable-next-line react/no-danger */}
              <Typography
                component="span"
                css={{
                  fontWeight: 300,
                  '> p': {
                    margin: 0,
                    '> a': {
                      color: 'inherit'
                    }
                  }
                }}
                dangerouslySetInnerHTML={{ __html: node.acf.info }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
