import React, { useState, useEffect } from 'react'
import {
  ListSubheader,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'

import { Club, School } from '..'
import { Role, Member, useMemdbRequest } from '../../hooks'

type SchoolClub = Pick<Club, 'id' | 'name'> & { roles: (Pick<Role, 'orgrole' | 'end'> & { member: Pick<Member, 'name'> })[] }

export const RegClubsOtherSchoolClubs: React.FC<{ clubs: Club[]; school: School }> = ({ clubs: userClubs, school }) => {
  const [clubs, setClubs] = useState<SchoolClub[]>([])
  const [selectedClub, setClub] = useState<SchoolClub>()
  const { request } = useMemdbRequest()

  useEffect(() => {
    request<{ clubs: SchoolClub[] }>({
      url: `/school/${school.id}`,
      params: {
        schema: { clubs: [{ id: 1, name: 1, roles: [{ member: { name: 1 }, orgrole: 1, end: 1, filter: '(orgrole=150)' }] }] }
      }
    }).then(response => {
      if (response && response.data) {
        setClubs(response.data.clubs.filter(({ id }) => !userClubs.some(club => club.id === id)))
      }
    })
  }, [])

  function handleClose() {
    setClub(undefined)
  }

  return clubs.length ? (
    <>
      <ListSubheader css={{ backgroundColor: 'inherit' }}>Andra klubbar på skolan</ListSubheader>
      {clubs.map(club => (
        <ListItem key={club.id} button dense onClick={() => setClub(club)}>
          <ListItemText primary={club.name} />
        </ListItem>
      ))}
      {selectedClub && (
        <Dialog open={!!selectedClub} onClose={handleClose}>
          <DialogTitle>Vill du använda den här klubben i din anmälan?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Kontakta då kansliet på <a href="mailto:yes2chess@schack.se">yes2chess@schack.se</a> eller ring{' '}
              <a href="tel:011-107420">011-107420</a>
            </DialogContentText>
            {!!selectedClub.roles.length && (
              <DialogContentText>
                Just nu är {selectedClub.roles.map(({ member }) => member.name).join(', ')} klubbadministratör
                {selectedClub.roles.length > 1 ? 'er' : ''} i {selectedClub.name}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Stäng
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  ) : null
}
