import React from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { ArrowBack } from '@material-ui/icons'
import useEventListener from '@use-it/event-listener'

export const BackButton: React.FC<ButtonProps & { onBack(): void }> = ({ onBack, ...props }) => {
  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      onBack()
    }

    return event
  }

  useEventListener('keydown', handleKeydown)

  return (
    <Button onClick={onBack} css={{ marginRight: 'auto' }} {...props}>
      <ArrowBack
        css={{
          marginRight: 8
        }}
      />
      Tillbaka
    </Button>
  )
}
