import React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

export function Password(props: Pick<TextFieldProps, 'autoFocus' | 'label' | 'error' | 'helperText'>) {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      name="password"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}
