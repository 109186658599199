import React from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'

export const ConfirmDialog: React.FC<{ open: boolean; button: React.ReactNode; onClose(): void }> = ({
  open,
  button,
  children,
  onClose
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onClose}>
        Stäng
      </Button>
      {button}
    </DialogActions>
  </Dialog>
)
