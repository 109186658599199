import React from 'react'
import { ButtonBase, useTheme, Typography, SvgIcon } from '@material-ui/core'
import { ButtonBaseProps } from '@material-ui/core/ButtonBase'
import { Info } from '@material-ui/icons'

export const RegOverviewButton: React.FC<
  {
    name: string
    Icon: typeof SvgIcon
    color: string
    message: string
  } & Pick<ButtonBaseProps, 'onClick' | 'disabled'>
> = ({ name, Icon, color, children, message, ...buttonProps }) => {
  const { spacing, palette } = useTheme()

  return (
    <ButtonBase
      {...buttonProps}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: spacing(2),
        textAlign: 'left',
        ':hover': {
          backgroundColor: palette.action.hover
        }
      }}
    >
      <div
        css={{
          width: spacing(18),
          paddingRight: spacing(2),
          textAlign: 'center'
        }}
      >
        <Icon css={{ fontSize: spacing(6) }} htmlColor={color} />
        <Typography variant="body2" color="textSecondary">
          {name}
        </Typography>
      </div>
      <div>
        {children}
        {message && (
          <Typography variant="body2" css={{ alignItems: 'center', display: 'inline-flex', color }}>
            <Info fontSize="inherit" css={{ marginRight: spacing(0.5) }} />
            {message}
          </Typography>
        )}
      </div>
    </ButtonBase>
  )
}
