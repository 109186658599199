import React, { useState } from 'react'

import { RegCheck, RegCheckProps, IRegAccount, RegLogin, RegCreateAccount, RegOverview, BackButton, Loading } from '..'
import { TournamentsContext, UserContext, RegContextProvider } from '../../contexts'

export function Reg() {
  const [account, setAccount] = useState<IRegAccount>()
  const { token } = React.useContext(UserContext)
  const { tournaments, isLoading } = React.useContext(TournamentsContext)

  const handleAccount: RegCheckProps['onAccount'] = _account => {
    setAccount(_account)
  }

  const buttons = (
    <BackButton
      css={{
        marginRight: 'auto'
      }}
      onBack={() => {
        setAccount(undefined)
      }}
    />
  )

  return isLoading || !tournaments ? (
    <Loading
      css={{
        height: 'calc(100vh - 64px)',
        background: 'initial'
      }}
    />
  ) : token ? (
    <RegContextProvider>
      <RegOverview />
    </RegContextProvider>
  ) : !account ? (
    <RegCheck onAccount={handleAccount} />
  ) : account && !account.id ? (
    <RegCreateAccount {...{ member: account, buttons }} />
  ) : (
    <RegLogin {...{ ...account, buttons }} />
  )
}
