import { Tooltip, useTheme } from '@material-ui/core'
import React from 'react'
import { ContenderCell, FlexRow, GroupResponse, ScrollShadow, Theme } from '..'


interface StandingProps {
  group: GroupResponse
  onContenderClick(contender: GroupResponse['contenders'][0]): (event: React.MouseEvent) => void
}

export const Standing: React.FC<StandingProps> = ({ group, onContenderClick }) => {
  const { palette } = useTheme<Theme>()

  return (
    <ScrollShadow>
      <FlexRow head>
        <div className="tiny">
          <Tooltip title="Placering" aria-label="place">
            <span className="tooltip">#</span>
          </Tooltip>
        </div>
        <ContenderCell>Namn</ContenderCell>
        <div className="tiny center">
          <Tooltip title="Vunna matcher" aria-label="wins">
            <span className="tooltip">+</span>
          </Tooltip>
        </div>
        <div className="tiny center">
          <Tooltip title="Oavgjorda matcher" aria-label="draws">
            <span className="tooltip">=</span>
          </Tooltip>
        </div>
        <div className="tiny center">
          <Tooltip title="Förlorade matcher" aria-label="losses">
            <span className="tooltip">-</span>
          </Tooltip>
        </div>
        <div className="small center">
          <Tooltip title="Partipoäng" aria-label="secpoints">
            <span className="tooltip">PP</span>
          </Tooltip>
        </div>
        <div className="small center">
          <Tooltip title="Matchpoäng" aria-label="points">
            <span className="tooltip">MP</span>
          </Tooltip>
        </div>
      </FlexRow>

      {(group.contenders || []).map(contender => (
        <FlexRow
          key={contender.team.id}
          onClick={onContenderClick(contender)}
          css={[
            contender.result.place <= group.topdelimiter && {
              position: 'relative',
              ':before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 1,
                left: 0,
                bottom: 0,
                width: 3,
                backgroundColor: palette.primary.main
              }
            },
            !contender.withdrawn && { textDecoration: 'line-through' }
          ]}
        >
          <div className="tiny">{contender.result.place}</div>
          <ContenderCell contender={contender} />
          <div className="tiny center">{contender.result.wins}</div>
          <div className="tiny center">{contender.result.draws}</div>
          <div className="tiny center">{contender.result.losses}</div>
          <div className="small center">{contender.result.secpoints}</div>
          <div className="small center">{contender.result.points}</div>
        </FlexRow>
      ))}
    </ScrollShadow>
  )
}
