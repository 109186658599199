import React, { useContext } from 'react'
import { Tournament } from '../lib/createPages'

interface Wp {
  tournament: Tournament
  category: Tournament['categories'][0]
}

export const WpContext = React.createContext<Wp>({
  // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
  tournament: {} as Tournament,
  // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
  category: {} as Wp['category']
})

export const WpContextProvider: React.FC<{ tournament: Tournament }> = ({ tournament, ...props }) => {
  return <WpContext.Provider value={{ tournament, category: tournament.categories[0] }} {...props} />
}

export const useWp = () => useContext(WpContext)
