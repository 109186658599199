import React from 'react'
import { Container } from '@material-ui/core'

import { TextBubble, LoginCard, LoginCardProps } from '..'

export const RegLogin: React.FC<LoginCardProps> = props => (
  <Container css={{ marginTop: 36 + 64, marginBottom: 36, maxWidth: 600 + 24 * 2 }}>
    <TextBubble>
      Ja, vi hittade dig. Fyll i ditt lösenord. Om du har glömt bort det kan du klicka på knappen <i>"GLÖMT LÖSENORD"</i>
    </TextBubble>
    <LoginCard {...props} />
  </Container>
)
