import React from 'react'
import { Typography } from '@material-ui/core'

import { GroupResponse } from '..'

export const ContenderCell: React.FC<{ contender?: GroupResponse['contenders'][0] }> = ({ contender, children }) => (
  <div
    className="flex ellipsis"
    css={{
      minWidth: 144,
      width: '100%'
    }}
  >
    {children}
    {contender && contender.team && contender.team.name && (
      <>
        <span>{contender.team.name}</span>
        {contender.team.school.name && !contender.team.name.toLowerCase().includes(contender.team.school.name.toLowerCase()) && (
          <Typography variant="caption" color="textSecondary" css={{ marginLeft: 4 }}>
            {contender.team.school.name}
          </Typography>
        )}
      </>
    )}
  </div>
)
