import React, { useContext } from 'react'
import { Container, CardContent, Card, TextField, Button, useTheme, Theme } from '@material-ui/core'
import { Club, playingYear } from 'y2c-helpers'

import { ButtonRow, Loading, TextBubble } from '..'
import { useMemdbRequest } from '../../hooks'
import { UserContext, useRegStatus } from '../../contexts'

export function RegCreateUpdateClub({
  buttons,
  club,
  onNewEditedClub
}: {
  buttons: React.ReactNode
  club?: Club
  onNewEditedClub(club: Club): void
}) {
  const { spacing } = useTheme<Theme>()
  const { request, isLoading, errorMessage } = useMemdbRequest()
  const {
    status: { school }
  } = useRegStatus()

  const { user } = useContext(UserContext)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement & { clubname: HTMLInputElement }>) => {
    event.preventDefault()

    if (school && user) {
      const name = event.currentTarget.clubname.value
      if (club) {
        request<{ result: number }>({
          method: 'POST',
          url: `/club/${club.id}`,
          data: { club: { name } }
        }).then(response => {
          if (response && response.data) {
            onNewEditedClub({ ...club, name })
          }
        })
      } else {
        const newClub: Omit<Club, 'id'> = {
          name,
          street: school.street,
          zipcode: school.zipcode,
          city: school.city,
          active: -1,
          noeconomy: 1,
          schoolclub: 1,
          schoolname: school.name,
          schoolid: school.id,
          countyid: school.countyid,
          vbdescr: `Deltar i Yes2Chess ${playingYear}`,
          schoolclubreason: 3, // yes2chess type
          phonenr: user.phone,
          email: user.email,
          startdate: new Date().toLocaleDateString('sv-SE'),
          startseason: `${new Date().getFullYear()}-01-01`,
          endseason: `${new Date().getFullYear()}-12-31`
        }

        request<{ result: number }>({
          method: 'POST',
          url: '/club',
          data: { club: newClub, admin: true, membership: true, districtid: school.districtid }
        }).then(response => {
          if (response && response.data) {
            onNewEditedClub({ id: response.data.result, ...newClub })
          }
        })
      }
    }
  }

  return (
    <Container css={{ maxWidth: 600 + 24 * 2 }}>
      <TextBubble>
        När ni döper en klubb ska ni undvika att ha siffror, årtal eller klassbeteckningen i namnet. Ord som lag ska också undvikas. Låt
        gärna barnen rösta fram roliga klubbnamn!
      </TextBubble>
      <form onSubmit={handleSubmit}>
        <Card css={{ position: 'relative' }}>
          <CardContent>
            <TextField
              label="Klubbnamn"
              name="clubname"
              defaultValue={club ? club.name : ''}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
            ></TextField>
          </CardContent>
          {isLoading && <Loading />}
        </Card>
        <ButtonRow css={{ margin: `${spacing(1)}px 0` }}>
          <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
            {club ? 'Spara' : 'Skapa'}
          </Button>
          {buttons}
        </ButtonRow>
      </form>
    </Container>
  )
}
