import React from 'react'
import { Paper } from '@material-ui/core'

import { Loading } from '..'

export const TournamentTableContainer: React.FC<{ isLoading: boolean }> = ({ isLoading, children }) => (
  <Paper css={{ marginBottom: 16, position: 'relative', overflow: 'hidden' }}>
    {isLoading ? (
      <div css={{ minHeight: 169 }}>
        <Loading />
      </div>
    ) : (
      children
    )}
  </Paper>
)
