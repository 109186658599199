import React, { FormEvent } from 'react'
import { Container, Card, CardContent, TextField, useTheme, Button } from '@material-ui/core'
import { Loading, IRegAccount, ButtonRow, TextBubble } from '..'
import { useMemdbRequest } from '../../hooks'
import { useWp } from '../../contexts'

export interface RegCheckProps {
  onAccount(account: IRegAccount): void
}
export const RegCheck: React.FC<RegCheckProps> = ({ onAccount }) => {
  const { request, isLoading, errorMessage } = useMemdbRequest()
  const { spacing } = useTheme()
  const { category } = useWp()

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const emailEl = ((event.target as unknown) as { email: HTMLInputElement }).email
    const email = emailEl.value
    const response = await request<{ id: 1 }[]>({ url: '/member', params: { email, schema: { id: 1 } } })
    if (response) {
      onAccount({ email, ...(response.data.length && { id: response.data[0].id }) })
    }
  }

  return (
    <Container css={{ marginTop: 36 + 64, marginBottom: 36, maxWidth: 600 + 24 * 2 }}>
      <form onSubmit={handleSubmit}>
        <TextBubble>
          Hej och välkommen till {category.slug.toUpperCase() === 'Y2C' ? 'Yes2Chess' : 'Sikta mot stjärnorna'} anmälan! Börja med att fylla
          i din mailadress och kolla om du redan finns i vårt system.
        </TextBubble>
        <Card css={{ position: 'relative' }}>
          <CardContent>
            <TextField
              name="email"
              autoFocus
              margin="dense"
              label="Email"
              type="text"
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
            />
          </CardContent>
          {isLoading && <Loading />}
        </Card>
        <ButtonRow css={{ margin: `${spacing(1)}px 0` }}>
          <Button type="submit" disabled={isLoading} variant="contained" color="primary">
            Kolla
          </Button>
        </ButtonRow>
      </form>
    </Container>
  )
}
