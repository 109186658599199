import React from 'react'

export const Logo: React.FC = () => (
  <svg height="64px" viewBox="0 0 630 94" fill="#BF175D">
    <path d="M46.57,32.45 C44.88,40.15 41.51,46.18 33.66,47.72 C34.05,48.81 34.75,49.58 35.1,50.72 C41.3,48.86 43.39,44.53 51.4,45.11 C63.49,46 66.31,58.75 63,71.59 C61.0400657,78.7906514 56.6704211,85.101576 50.62,89.47 C47.88,91.47 45.29,93.96 40.62,93.47 C37.05,93.12 34.47,91.7 31.1,92.3 C29.54,92.57 27.98,93.41 26.1,93.47 C13.09,93.94 6.48,83 2.74,73.42 C1.36,69.89 0,66 0,61.67 C0,53.05 5,45.38 14.22,45.37 C21.77,45.37 24.36,49.69 31.22,51.11 C29.22,46.34 26.64,42.11 21.82,40.11 C22.03,39.05 23.69,38.25 24.96,38.29 C28.3,38.37 29.66,42.7 31.48,44.41 C31.19,35.16 36.92,30.07 46.57,32.45 Z M34.31,40.8 C34.01,42.24 33.57,43.89 34.18,45.11 C39.47,43.66 42.18,39.65 43.57,34.28 C37.61,34 35.29,36.2 34.31,40.8 Z M6.52,66.5 C7.05,72.61 11.63,76.5 15,79.42 C14.92,79.42 14.85,79.42 14.87,79.29 C12.63,74.87 9.62,68.92 10.05,62.07 C10.3493322,58.8395051 11.3748777,55.7184281 13.05,52.94 C9.64,56.24 6,59.92 6.52,66.5 Z" />
    <path d="M99.36,94 C92.5582378,94.011 85.8240728,92.6505626 79.56,90 L81.86,79.32 C87.5645386,81.2185517 93.5283076,82.2237571 99.54,82.3 C106.63,82.3 108.01,80.92 108.01,75.76 C108.01,70.24 107.74,69.59 97.7,67.39 C82.87,64.16 80.7,61.22 80.7,47.96 C80.7,35.71 85.02,30.83 101.87,30.83 C108.126173,30.8446194 114.363583,31.5149483 120.48,32.83 L119.09,44.44 C113.488745,43.3382079 107.798249,42.7524215 102.09,42.69 C95.92,42.69 94.54,43.79 94.54,48.3 C94.54,54.2 94.81,54.3 104.48,56.59 C121.06,60.59 121.89,63.77 121.89,75.84 C121.89,87.91 117.87,94 99.36,94 Z" />
    <path d="M139.69,76 C139.69,80.6 141.62,82.72 146.96,82.72 C150.063106,82.7257405 153.155531,82.3563954 156.17,81.62 L157.83,92.62 C154.285038,93.4947176 150.651041,93.9577754 147,94 C132.45,94 126.47,88.57 126.47,75.76 L126.47,65.54 C126.47,52.75 132.47,47.31 147,47.31 C150.664131,47.3535762 154.31122,47.8165922 157.87,48.69 L156.21,59.75 C153.185154,59.071135 150.099522,58.699251 147,58.64 C141.66,58.64 139.73,60.76 139.73,65.36 L139.69,76 Z" />
    <path d="M190.42,93.08 L190.42,63.43 C190.42,61.43 189.5,60.2 187.2,60.2 C184.9,60.2 180.38,62.04 177.53,63.52 L177.53,93.08 L164.27,93.08 L164.27,29.91 L177.34,28.07 L177.34,51.46 C182.78,48.97 188.21,47.31 193.34,47.31 C200.98,47.31 203.66,53.02 203.66,61.68 L203.66,93.08 L190.42,93.08 Z" />
    <path
      d="M236.92,93.08 L236,90.22 C232.110115,92.5157656 227.713009,93.8142859 223.2,94 C214.91,94 211.51,88 211.51,80.37 C211.51,71.37 215.65,67.75 224.86,67.75 L234.53,67.75 L234.53,64.35 C234.53,59.75 233.7,58.18 226.6,58.18 C222.517672,58.2136148 218.448375,58.6456637 214.45,59.47 L212.7,49.52 C217.955418,48.0906308 223.3738,47.3477881 228.82,47.31 C244.38,47.31 247.82,52.01 247.82,64.44 L247.82,93.08 L236.92,93.08 Z M234.53,77 L228.17,77 C225.32,77 224.17,77.74 224.17,80.22 C224.17,82.7 225.17,83.72 227.77,83.72 C230.100367,83.7255133 232.400387,83.19158 234.49,82.16 L234.53,77 Z"
      fillRule="nonzero"
    />
    <path d="M267,76 C267,80.6 268.93,82.72 274.28,82.72 C277.379773,82.7251302 280.468815,82.3557881 283.48,81.62 L285.14,92.62 C281.595169,93.4954193 277.96109,93.9584876 274.31,94 C259.75,94 253.77,88.57 253.77,75.76 L253.77,65.54 C253.77,52.75 259.77,47.31 274.31,47.31 C277.970843,47.3534618 281.614634,47.8164849 285.17,48.69 L283.51,59.75 C280.488434,59.0717352 277.406179,58.6998544 274.31,58.64 C268.96,58.64 267.03,60.76 267.03,65.36 L267,76 Z" />
    <path
      d="M291.61,93.08 L291.61,29.91 L304.87,28.07 L304.87,93.07 L291.61,93.08 Z M318,48.23 L332.18,48.23 L320.43,70.52 L332.77,93.08 L318.59,93.08 L306.59,70.52 L318,48.23 Z"
      fillRule="nonzero"
    />
    <path
      d="M354.86,42.61 L354.86,28.53 L368.12,28.53 L368.12,42.61 L354.86,42.61 Z M354.86,93.08 L354.86,48.23 L368.12,48.23 L368.12,93.08 L354.86,93.08 Z"
      fillRule="nonzero"
    />
    <path d="M408.82,94 C403.289452,93.8668481 397.803998,92.9683106 392.52,91.33 L394.65,81.48 C399.043753,82.7784965 403.598476,83.451774 408.18,83.48 C412.13,83.48 412.78,82.38 412.78,79.71 C412.78,76.85 412.69,76.48 405.51,74.71 C394.65,72.04 393.63,69.1 393.63,60.71 C393.63,51.22 397.31,47.26 410.76,47.26 C415.341867,47.278706 419.909509,47.7713277 424.39,48.73 L423.19,59.05 C419.119609,58.2781858 414.992012,57.8466947 410.85,57.76 C406.98,57.76 406.06,58.76 406.06,60.76 C406.06,63.52 406.43,63.89 411.77,65.08 C424.57,67.94 425.49,70.7 425.49,79.64 C425.49,88.93 421.62,94 408.82,94 Z" />
    <path
      d="M432.39,93.08 L432.39,29.91 L445.65,28.07 L445.65,93.07 L432.39,93.08 Z M458.82,48.23 L473,48.23 L461.21,70.52 L473.56,93.08 L459.37,93.08 L447.37,70.52 L458.82,48.23 Z"
      fillRule="nonzero"
    />
    <path
      d="M475.85,75.86 L475.85,65.45 C475.85,56.25 480.18,47.31 496.11,47.31 C512.04,47.31 516.37,56.25 516.37,65.45 L516.37,75.86 C516.37,85.07 512,94 496.11,94 C480.22,94 475.85,85.07 475.85,75.86 Z M503.11,76.23 L503.11,65.08 C503.11,60.85 500.9,58.46 496.11,58.46 C491.32,58.46 489.11,60.85 489.11,65.08 L489.11,76.23 C489.11,80.46 491.33,82.86 496.11,82.86 C500.89,82.86 503.11,80.46 503.11,76.23 Z"
      fillRule="nonzero"
    />
    <polygon points="523.73 93.08 523.73 29.91 537 28.07 537 93.07" />
    <path
      d="M570.69,93.08 L569.77,90.22 C565.888485,92.5098985 561.502554,93.8081614 557,94 C548.71,94 545.31,88 545.31,80.37 C545.31,71.37 549.45,67.75 558.66,67.75 L568.33,67.75 L568.33,64.35 C568.33,59.75 567.5,58.18 560.41,58.18 C556.327614,58.2125937 552.258217,58.6446531 548.26,59.47 L546.5,49.52 C551.755418,48.0906308 557.1738,47.3477881 562.62,47.31 C578.18,47.31 581.62,52.01 581.62,64.44 L581.62,93.08 L570.69,93.08 Z M568.3,77 L561.94,77 C559.09,77 557.94,77.74 557.94,80.22 C557.94,82.7 558.94,83.72 561.53,83.72 C563.860367,83.7255133 566.160387,83.19158 568.25,82.16 L568.3,77 Z"
      fillRule="nonzero"
    />
    <path d="M616.46,93.08 L616.46,63.43 C616.46,61.43 615.53,60.2 613.23,60.2 C610.93,60.2 606.42,61.86 603.56,63.43 L603.56,93.08 L590.3,93.08 L590.3,48.23 L600.16,48.23 L601.54,51.64 C607.134866,48.9912947 613.213316,47.5176281 619.4,47.31 C627.05,47.31 629.71,53.02 629.71,61.68 L629.71,93.08 L616.46,93.08 Z" />
    <path d="M85.47,17.16 C83.9728667,17.1670024 82.4897892,16.8710672 81.11,16.29 L81.62,13.94 C82.8763683,14.3507616 84.1882562,14.5665992 85.51,14.58 C87.07,14.58 87.38,14.28 87.38,13.14 C87.38,12 87.32,11.78 85.11,11.3 C81.84,10.59 81.35,9.94 81.35,7.02 C81.35,4.32 82.35,3.25 86.02,3.25 C87.3948685,3.25505653 88.7655156,3.40251001 90.11,3.69 L89.81,6.25 C88.5750067,6.00261928 87.3194684,5.87204329 86.06,5.86 C84.7,5.86 84.4,6.11 84.4,7.1 C84.4,8.4 84.46,8.42 86.59,8.93 C90.24,9.8 90.42,10.51 90.42,13.16 C90.42,15.81 89.55,17.16 85.47,17.16 Z" />
    <polygon points="98.88 17 94.54 17 90.73 3.45 93.85 3.45 96.71 14.2 99.57 3.45 102.7 3.45" />
    <polygon points="103.51 17 103.51 3.45 112 3.45 112 6.15 106.54 6.15 106.54 8.62 111.54 8.62 111.54 11.32 106.54 11.32 106.54 14.26 112 14.26 112 17" />
    <path
      d="M118.67,12.76 L116.88,12.76 L116.88,17 L113.88,17 L113.88,3.45 L119.5,3.45 C122.78,3.45 124,4.75 124,7.28 L124,9 C124.16295,10.4468789 123.346672,11.8263885 122,12.38 L125.26,17 L121.65,17 L118.67,12.76 Z M120.92,7.57 C120.92,6.57 120.53,6.15 119.3,6.15 L116.88,6.15 L116.88,10.15 L119.3,10.15 C120.59,10.15 120.92,9.68 120.92,8.67 L120.92,7.57 Z"
      fillRule="nonzero"
    />
    <polygon points="126.48 17 126.48 3.45 129.48 3.45 129.48 17" />
    <path d="M136.4,17.16 C133.12,17.16 131.4,15.48 131.4,13.02 L131.4,7.38 C131.4,4.89 133.07,3.25 136.4,3.25 C137.849668,3.26042953 139.295472,3.40099381 140.72,3.67 L140.36,6.33 C139.034794,6.13721209 137.698884,6.0269995 136.36,6 C134.84,6 134.46,6.8 134.46,7.85 L134.46,12.5 C134.373906,13.027314 134.545321,13.5639881 134.921142,13.9437653 C135.296964,14.3235425 135.831813,14.5005677 136.36,14.42 C136.965452,14.4241716 137.570398,14.3840646 138.17,14.3 L138.17,10.16 L141.03,10.16 L141.03,16.51 C139.523969,16.9388721 137.965904,17.1576069 136.4,17.16 Z" />
    <polygon points="143.1 17 143.1 3.45 151.6 3.45 151.6 6.15 146.14 6.15 146.14 8.62 151.14 8.62 151.14 11.32 146.14 11.32 146.14 14.26 151.6 14.26 151.6 17" />
    <path d="M157.39,17.16 C155.889556,17.1678006 154.403052,16.8718605 153.02,16.29 L153.53,13.94 C154.790007,14.3499374 156.105061,14.5657411 157.43,14.58 C158.99,14.58 159.29,14.28 159.29,13.14 C159.29,12 159.23,11.78 157.02,11.3 C153.75,10.59 153.27,9.94 153.27,7.02 C153.27,4.32 154.22,3.25 157.93,3.25 C159.308204,3.25450931 160.682223,3.40196502 162.03,3.69 L161.72,6.25 C160.494844,6.00448391 159.249437,5.87391704 158,5.86 C156.64,5.86 156.34,6.11 156.34,7.1 C156.34,8.4 156.4,8.42 158.53,8.93 C162.18,9.8 162.36,10.51 162.36,13.16 C162.36,15.81 161.46,17.16 157.39,17.16 Z" />
    <path d="M171.67,17.16 C170.172867,17.1670024 168.689789,16.8710672 167.31,16.29 L167.82,13.94 C169.076368,14.3507616 170.388256,14.5665992 171.71,14.58 C173.27,14.58 173.58,14.28 173.58,13.14 C173.58,12 173.52,11.78 171.31,11.3 C168.04,10.59 167.55,9.94 167.55,7.02 C167.55,4.32 168.55,3.25 172.22,3.25 C173.594868,3.25505653 174.965516,3.40251001 176.31,3.69 L176,6.25 C174.765007,6.00261928 173.509468,5.87204329 172.25,5.86 C170.89,5.86 170.59,6.11 170.59,7.1 C170.59,8.4 170.65,8.42 172.78,8.93 C176.43,9.8 176.61,10.51 176.61,13.16 C176.61,15.81 175.75,17.16 171.67,17.16 Z" />
    <path d="M181,12.54 C181,13.71 181.38,14.4 182.88,14.4 C184.049858,14.3837502 185.216693,14.2767626 186.37,14.08 L186.8,16.73 C185.513158,17.0172697 184.198516,17.161478 182.88,17.16 C179.47,17.16 177.95,15.48 177.95,13.02 L177.95,7.38 C177.95,4.93 179.47,3.25 182.88,3.25 C184.197888,3.25012707 185.511999,3.39092462 186.8,3.67 L186.37,6.33 C185.213613,6.12972921 184.043441,6.01939873 182.87,6 C181.37,6 180.99,6.69 180.99,7.87 L181,12.54 Z" />
    <polygon points="195.72 17 195.72 11.52 191.35 11.52 191.35 17 188.35 17 188.35 3.45 191.35 3.45 191.35 8.82 195.72 8.82 195.72 3.45 198.72 3.45 198.72 17" />
    <path
      d="M212.09,17 L208.92,17 L208.19,14.36 L203.67,14.36 L202.94,17 L199.78,17 L203.89,3.49 L208,3.49 L212.09,17 Z M204.36,11.82 L207.46,11.82 L205.92,6.17 L204.36,11.82 Z"
      fillRule="nonzero"
    />
    <path d="M215.64,12.54 C215.64,13.71 216.03,14.4 217.53,14.4 C218.699835,14.3833106 219.866636,14.276326 221.02,14.08 L221.45,16.73 C220.163158,17.0172697 218.848516,17.161478 217.53,17.16 C214.12,17.16 212.6,15.48 212.6,13.02 L212.6,7.38 C212.6,4.93 214.12,3.25 217.53,3.25 C218.847888,3.25012707 220.161999,3.39092462 221.45,3.67 L221,6.33 C219.853438,6.13169376 218.693388,6.02137202 217.53,6 C216.03,6 215.64,6.69 215.64,7.87 L215.64,12.54 Z" />
    <path
      d="M223,3.45 L226,3.45 L226,17 L223,17 L223,3.45 Z M226.43,10.06 L230.08,3.45 L233.51,3.45 L229.71,10.06 L233.79,16.96 L230.36,16.96 L226.43,10.06 Z"
      fillRule="nonzero"
    />
    <polygon points="235 17 235 3.45 243.58 3.45 243.58 6.15 238 6.15 238 9 243.11 9 243.11 11.69 238 11.69 238 17" />
    <path
      d="M255.05,13 C255.05,15.05 253.63,17.14 249.81,17.14 C245.99,17.14 244.58,15.05 244.58,13 L244.58,7.38 C244.58,5.38 246,3.25 249.81,3.25 C253.62,3.25 255.05,5.33 255.05,7.38 L255.05,13 Z M246.61,2.21 L246.61,0 L248.92,0 L248.92,2.21 L246.61,2.21 Z M252,7.65 C252,6.59 251.33,5.94 249.8,5.94 C248.27,5.94 247.61,6.59 247.61,7.65 L247.61,12.76 C247.61,13.81 248.28,14.46 249.8,14.46 C251.32,14.46 252,13.81 252,12.76 L252,7.65 Z M250.7,2.21 L250.7,0 L253,0 L253,2.21 L250.7,2.21 Z"
      fillRule="nonzero"
    />
    <path
      d="M261.71,12.76 L259.92,12.76 L259.92,17 L256.92,17 L256.92,3.45 L262.54,3.45 C265.82,3.45 267.04,4.75 267.04,7.28 L267.04,9 C267.20295,10.4468789 266.386672,11.8263885 265.04,12.38 L268.3,17 L264.69,17 L261.71,12.76 Z M264,7.57 C264,6.57 263.61,6.15 262.37,6.15 L259.96,6.15 L259.96,10.15 L262.37,10.15 C263.67,10.15 264,9.68 264,8.67 L264,7.57 Z"
      fillRule="nonzero"
    />
    <path
      d="M275.18,17 L269.42,17 L269.42,3.45 L274.75,3.45 C277.57,3.45 278.61,4.59 278.61,6.45 L278.61,7.79 C278.64261,8.75555839 278.030081,9.62535058 277.11,9.92 C278.278246,10.1780484 279.110009,11.2135937 279.11,12.41 L279.11,13.73 C279.09,15.64 277.88,17 275.18,17 Z M275.56,6.9 C275.56,6.31 275.2,5.9 274.35,5.9 L272.46,5.9 L272.46,9 L274.35,9 C275.16,9 275.56,8.6 275.56,8 L275.56,6.9 Z M276.05,12.54 C276.05,11.78 275.58,11.4 274.77,11.4 L272.46,11.4 L272.46,14.5 L274.35,14.5 C275.3,14.5 276.05,14.3 276.05,13.32 L276.05,12.54 Z"
      fillRule="nonzero"
    />
    <path d="M290.6,3.45 L290.6,13 C290.6,15.05 289.32,17.14 285.51,17.14 C281.7,17.14 280.42,15.05 280.42,13 L280.42,3.45 L283.42,3.45 L283.42,12.74 C283.42,13.83 283.95,14.46 285.47,14.46 C286.99,14.46 287.52,13.83 287.52,12.74 L287.52,3.45 L290.6,3.45 Z" />
    <polygon points="295.53 8.07 295.53 17 292.63 17 292.63 3.45 296.36 3.45 300.8 12.76 300.8 3.45 303.7 3.45 303.7 17 300 17" />
    <path
      d="M311.07,17 L305.84,17 L305.84,3.45 L311.07,3.45 C314.4,3.45 315.64,5.13 315.64,6.96 L315.64,13.45 C315.64,15.25 314.4,17 311.07,17 Z M312.59,7.83 C312.59,6.77 312.23,6.19 310.7,6.19 L308.88,6.19 L308.88,14.3 L310.7,14.3 C312.23,14.3 312.59,13.71 312.59,12.66 L312.59,7.83 Z"
      fillRule="nonzero"
    />
  </svg>
)
