import React from 'react'
import { CardContent, Typography } from '@material-ui/core'

import { Assistant } from '.'

export const TextBubble: React.FC = ({ children }) => (
  <CardContent css={{ position: 'relative' }}>
    <Typography>{children}</Typography>
    <Assistant
      css={{
        position: 'absolute',
        left: -198,
        bottom: -144,
        width: 184
      }}
    />
    <svg viewBox="0 0 63 45" css={{ position: 'absolute', bottom: 0, left: -32, width: 63 }}>
      <path
        d="M36.1132813,0.74609375 C33.7566537,11.5564575 28.949362,20.8728637 21.6914062,28.6953125 C14.4334505,36.5177613 7.44209339,41.457452 0.717334869,43.5143848 C9.71601235,40.9342141 19.2912333,38.9175016 29.4429976,37.4642473 C39.594762,36.010993 50.5663363,35.2843659 62.3577204,35.2843659"
        id="Path"
        stroke="#000000"
        fill="none"
      ></path>
    </svg>
  </CardContent>
)
