import React, { useContext } from 'react'
import { Card, TextField, Divider, CardContent, Button, useTheme, Theme } from '@material-ui/core'

import { UserContext } from '../../contexts'
import { Password } from '../fields'
import { ButtonRow, Loading } from '..'
import { useMemdbRequest } from '../../hooks'

export function RegAccount({ buttons }: { buttons: React.ReactNode }) {
  const { spacing } = useTheme<Theme>()
  const { user } = useContext(UserContext)
  const { request, isLoading } = useMemdbRequest()

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement & { email: HTMLInputElement; phone: HTMLInputElement; password: HTMLInputElement }>
  ) => {
    event.preventDefault()

    if (user) {
      const { email, phone, password } = event.currentTarget

      request({
        method: 'POST',
        url: `/member/${user.id}`,
        data: {
          email: email.value || user.email,
          phone: phone.value || user.phone,
          ...(password.value && {
            password: password.value,
            password2: password.value
          })
        }
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card css={{ position: 'relative' }}>
        <CardContent>
          <TextField label="Användarnamn" name="email" defaultValue={user ? user.email : ''} fullWidth margin="normal" />
          <TextField label="Telefonnummer" name="phone" defaultValue={user ? user.phone : ''} fullWidth margin="normal" />
        </CardContent>
        <Divider />
        <CardContent>
          <Password label="Nytt lösenord" />
        </CardContent>
        {isLoading && <Loading />}
      </Card>
      <ButtonRow>
        <Button type="submit" variant="contained" color="primary" disabled={isLoading} css={{ margin: `${spacing(1)}px 0` }}>
          Spara
        </Button>
        {buttons}
      </ButtonRow>
    </form>
  )
}
