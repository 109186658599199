import React from 'react'
import Img from 'gatsby-image'
import { Node, HTMLElement, TextNode, NodeType } from 'node-html-parser'
import { Typography, Grid, Button, Box, Card } from '@material-ui/core'
import { TypographyProps } from '@material-ui/core/Typography'
import { Link as GatsbyLink } from 'gatsby'

import { Assistant } from '.'

import knightSvg from '../assets/knight.svg'
import spaceSvg from '../assets/space.svg'
import { useWp } from '../contexts'

const variantMap: { [key: string]: TypographyProps['variant'] } = { p: 'body1', h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6' }
const getVariant = (tag: string): TypographyProps['variant'] => variantMap[tag] || 'body1'

const isTextNode = (node: Node): node is TextNode => node.nodeType === NodeType.TEXT_NODE
const isHTMLElement = (node: Node): node is HTMLElement => node.nodeType === NodeType.ELEMENT_NODE

const spreadNodes = (nodes: Node[]) => (
  <>
    {nodes.map((node, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <WPAst key={index} node={node} />
    ))}
  </>
)

const HeroSvg: React.FC<{ node: HTMLElement }> = () => {
  const { category } = useWp()

  const src = category.slug.toUpperCase() === 'SMS' ? spaceSvg : knightSvg

  return (
    <div css={{ minWidth: 220, height: '100%' }}>
      <img src={src} alt="hero" css={{ position: 'absolute', right: 0, top: 0, height: '100%' }} />
    </div>
  )
}

const HeroCard: React.FC<{ node: HTMLElement }> = ({ node, ...props }) => {
  const { category } = useWp()

  const background =
    category.slug.toUpperCase() === 'SMS'
      ? 'radial-gradient(circle at 50% 50%, #3C3267 0%, #1A1836 100%)'
      : 'radial-gradient(circle at 10% 0%, rgba(217, 76, 82, 1) 0%, rgba(77, 46, 94, 1) 35%, rgba(33, 17, 62, 1) 100%)'
  return (
    <Card
      className={node.attributes.class}
      css={[
        {
          position: 'relative',
          minHeight: 220,
          background: 'rgb(217,76,82)',
          display: 'flex',
          alignItems: 'center',
          '> .MuiBox': {
            position: 'relative',
            zIndex: 10,
            color: '#FFF'
          }
        },
        {
          background
        }
      ]}
      {...props}
    />
  )
}

const Image: React.FC<{ node: HTMLElement }> = ({ node }) => {
  let aspectRatio = 16 / 9
  const match = node.attributes.src.match(/(\d+)x(\d+)/)
  if (match) {
    aspectRatio = +match[1] / +match[2]
  }

  return (
    <Img
      fluid={{
        aspectRatio,
        sizes: node.attributes.sizes,
        src: node.attributes.src,
        srcSet: node.attributes.srcset
      }}
      alt={node.attributes.alt}
    />
  )
}

const Element: React.FC<{ node: HTMLElement }> = ({ node, ...props }) =>
  node.tagName === 'div' ? (
    node.classNames.includes('wp-block-columns') ? (
      <React.Fragment {...props} />
    ) : node.classNames.includes('wp-block-column') ? (
      <Grid
        item
        xs={12}
        className={node.attributes.class}
        css={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          '&.MuiGrid-grid-sm-8 > *': {
            margin: '8px 16px'
          },
          '&.circle > div': {
            borderRadius: '50%',
            '> div': {
              paddingBottom: '100% !important'
            }
          },
          '& .image-overflow-circle': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            transform: 'translate(20%, 0)',
            backgroundColor: '#C0175D',
            width: 144,
            height: 144,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            '> *': {
              color: '#FFF'
            }
          },
          '& a': {
            textDecoration: 'none'
          }
        }}
        {...props}
      />
    ) : node.classNames.includes('wp-block-row') ? (
      <div
        css={{
          '> .wp-block-group__inner-container': {
            display: 'flex',
            '> .wp-block-button': {
              marginRight: 16
            }
          }
        }}
        {...props}
      />
    ) : node.classNames.includes('MuiCard') ? (
      <HeroCard node={node} {...props} />
    ) : node.classNames.includes('MuiBox') ? (
      <Box m={2} className={node.attributes.class} {...props} />
    ) : (
      <div className={node.attributes.class} {...props} />
    )
  ) : ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(node.tagName) ? (
    <Typography variant={getVariant(node.tagName)} {...props} />
  ) : node.tagName === 'img' ? (
    <Image node={node} />
  ) : node.classNames.includes('wp-block-button__link') ? (
    node.attributes.href.startsWith('http') ? (
      <a href={node.attributes.href}>
        <Button variant="contained" color="primary" className={node.attributes.class} {...props} />
      </a>
    ) : (
      <GatsbyLink to={node.attributes.href}>
        <Button variant="contained" color="primary" className={node.attributes.class} {...props} />
      </GatsbyLink>
    )
  ) : node.tagName === 'svg' && node.attributes.name === 'assistant' ? (
    <Assistant />
  ) : node.tagName === 'svg' && node.attributes.name === 'hero-svg' ? (
    <HeroSvg node={node} {...props} />
  ) : (
    <React.Fragment {...props} />
  )

const Text: React.FC<{ node: TextNode }> = ({ node }) => <>{node.toString()}</>

export const WPAst: React.FC<{ node: Node }> = ({ node }) =>
  isHTMLElement(node) ? <Element node={node}>{spreadNodes(node.childNodes)}</Element> : isTextNode(node) ? <Text node={node} /> : null
