import React, { useEffect, useContext, useState } from 'react'
import { decode } from 'jsonwebtoken'
import { useCookies } from 'react-cookie'
import { useMemdbRequest } from '../hooks'

export interface IUser {
  id: number
  name: string
  email: string
  phone: string
  zipcode: number
}

export interface IUserContext {
  token?: string
  user?: IUser
  setToken: React.Dispatch<string | undefined>
  decodedToken?: { [key: string]: unknown }
}

export const UserContext = React.createContext<IUserContext>({
  setToken: () => {}
})

export const UserContextProvider: React.FC = props => {
  const [cookies, setCookie, removeCookie] = useCookies([])
  const [token, setToken] = useState<string | undefined>(cookies.memdbToken)
  const [user, setUser] = useState<IUser>()
  const [decodedToken, setDecodedToken] = useState<{ [key: string]: unknown }>()

  const { request } = useMemdbRequest()

  useEffect(() => {
    setDecodedToken(token ? (decode(token) as { [key: string]: unknown }) : undefined)
    if (token && !user) {
      setCookie('memdbToken', token, { path: '/' })
      request<IUser>({
        url: '/member/me',
        headers: { token },
        params: {
          // , roles: [{ orgtype: 1, orgrole: 1, end: 1, filter: '(end=1970-01-01)' }]
          schema: { id: 1, name: 1, email: 1, phone: 1, zipcode: 1 }
        }
      }).then(response => {
        if (response) {
          setUser(response.data)
        } else {
          setToken(undefined)
          removeCookie('memdbToken', { path: '/' })
        }
      })
    } else if (!token && user) {
      removeCookie('memdbToken', { path: '/' })
      setUser(undefined)
    }
  }, [token])

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
        decodedToken,
        user
      }}
      {...props}
    />
  )
}

export const useUser = () => useContext(UserContext)
