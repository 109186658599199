import React, { useState, useContext } from 'react'
import {
  Card,
  Button,
  useTheme,
  Theme,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core'
import { Tournament, Group, Team, getTeamsStatus } from 'y2c-helpers'

import { ButtonRow, BackButton, RegCreateUpdateTeam, ConfirmDialog, Loading } from '..'
import { Warning, Check, Close } from '@material-ui/icons'
import { yellow, green } from '@material-ui/core/colors'
import { TournamentsContext, useRegStatus } from '../../contexts'
import { useMemdbRequest } from '../../hooks'

export const getGroups = (tournaments?: Tournament[]) =>
  (tournaments || []).flatMap(({ classes }) => classes.flatMap(({ groups: g }) => g.flatMap(group => group)))

function prependTeamName(_team: Team, groups: { [key: number]: Group }) {
  const group = groups[_team.groupid] || {}
  const str = ((group.name || '').startsWith('Yes2Chess') && (group.name || '').split(' ')[1]) || ''
  return str ? <span css={{ fontWeight: 300 }}>{str}: </span> : ''
}

const TeamStatus: React.FC<{ team: Team }> = ({ team }) => {
  const {
    status: { clubs = [] }
  } = useRegStatus()
  const {
    teamsStatus: { status, message }
  } = getTeamsStatus([team], clubs)

  return (
    <Tooltip key={team.id} title={message || 'Det här laget är godkänt för deltagande i Yes2Chess'}>
      {status !== 3 ? <Warning fontSize="small" css={{ color: yellow[800] }} /> : <Check fontSize="small" css={{ color: green[800] }} />}
    </Tooltip>
  )
}

export function RegTeams({ buttons }: { buttons: React.ReactNode }) {
  const { tournaments } = useContext(TournamentsContext)
  const {
    status: { teams = [] },
    setStatus,
    page,
    setPage
  } = useRegStatus()

  const groups = getGroups(tournaments).reduce<{ [key: number]: Group }>((obj, group) => ({ ...obj, [group.id]: group }), {})
  const { spacing } = useTheme<Theme>()
  const [team, setTeam] = useState<Team>()
  const [confirmRemoval, setConfirmRemoval] = useState(false)
  const { request, isLoading } = useMemdbRequest()

  const handleTeamSubmit = (_team: Team) => {
    if (team) {
      teams.splice(teams.indexOf(team), 1, _team)
    } else {
      teams.push(_team)
    }
    setStatus({ teams: [...teams] })
    setPage(['teams'])
  }

  const handleRemoveTeam = async () => {
    if (team) {
      const response = await request<{ result: number }>({
        method: 'DELETE',
        url: `tournament/group/${team.groupid}/contender/${team.id}/teamnumber/1`
      })
      if (response && response.data) {
        teams.splice(teams.indexOf(team), 1)
        setStatus({ teams: [...teams] })
      }
      setConfirmRemoval(false)
    }
  }

  return !page[1] ? (
    <>
      <Card css={{ position: 'relative' }}>
        <List css={{ maxHeight: 314, overflow: 'auto', backgroundColor: 'inherit' }} disablePadding>
          <ListSubheader css={{ backgroundColor: 'inherit' }}>Anmälda lag</ListSubheader>
          {teams.map(_team => (
            <ListItem
              key={_team.id}
              button
              dense
              onClick={() => {
                setTeam(_team)
                setPage(['teams', 'team'])
              }}
              selected={false}
            >
              <ListItemText>
                {prependTeamName(_team, groups)}
                {_team.name}
              </ListItemText>
              <TeamStatus team={_team} />
              <ListItemSecondaryAction>
                <Tooltip key={_team.id} css={{ position: 'relative' }} title={`Ta bort anmälan för ${_team.name}`} aria-label="add">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setTeam(_team)
                      setConfirmRemoval(true)
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {isLoading && <Loading />}
      </Card>
      <ButtonRow>
        <Button
          variant="contained"
          color="primary"
          css={{ margin: `${spacing(1)}px 0` }}
          onClick={() => {
            setTeam(undefined)
            setPage(['teams', 'team'])
          }}
        >
          Ny anmälan
        </Button>
        {buttons}
      </ButtonRow>

      <ConfirmDialog
        open={confirmRemoval}
        button={
          <Button autoFocus color="primary" onClick={handleRemoveTeam}>
            Ta bort
          </Button>
        }
        onClose={() => setConfirmRemoval(false)}
      >
        <Typography>Är du säker på att du vill ta bort anmälan för {team ? team.name : ''}?</Typography>
      </ConfirmDialog>
    </>
  ) : (
    <RegCreateUpdateTeam
      team={team}
      onSubmit={handleTeamSubmit}
      buttons={<BackButton onBack={() => (teams.length ? setPage(['teams']) : setPage(['overview']))} />}
    />
  )
}
