import React, { DOMAttributes, useState, useRef, useEffect } from 'react'
import { Card, TextField, CardContent, Button, useTheme, Theme, MenuItem, ListItemText, Tooltip, Typography } from '@material-ui/core'
import { yellow, green, blue, grey } from '@material-ui/core/colors'
import { Edit, Warning, Check } from '@material-ui/icons'
import { ClassNames } from '@emotion/core'
import { Club, getDocumentsStatus } from 'y2c-helpers'

import { ButtonRow, TextBubble } from '..'
import { useMemdbRequest, useY2CRequest } from '../../hooks'
import { useRegStatus } from '../../contexts'

const DocStatus: React.FC<{ club: Club }> = ({ club }) => {
  const { status } = useRegStatus()
  const { documentsStatus } = getDocumentsStatus(
    status.documents,
    [club],
    [{ id: -1, name: 'fake', groupid: -1, clubid: club.id, roles: [], players: [] }]
  )

  return (
    <Tooltip
      key={club.id}
      title={
        documentsStatus.status === 1
          ? 'Generera dokument för den här klubben'
          : documentsStatus.message || 'Vi har fått in alla dokument för den här klubben'
      }
    >
      {documentsStatus.status === 0 ? (
        <Warning fontSize="small" css={{ color: grey[500] }} />
      ) : documentsStatus.status === 1 ? (
        <Warning fontSize="small" css={{ color: yellow[800] }} />
      ) : documentsStatus.status === 2 ? (
        <Check fontSize="small" css={{ color: blue[800] }} />
      ) : (
        <Check fontSize="small" css={{ color: green[800] }} />
      )}
    </Tooltip>
  )
}

export function RegDocuments({ buttons }: { buttons: React.ReactNode }) {
  const { spacing } = useTheme<Theme>()
  const {
    status: { clubs = [], documents = {} },
    setStatus
  } = useRegStatus()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeHeight, setIframeHeight] = useState<number>(0)
  const { request } = useMemdbRequest()
  const { request: y2cRequest } = useY2CRequest()

  const [club, setClub] = useState<Club | undefined>(clubs[0] || undefined)

  useEffect(() => {
    if (club && club.startdate && iframeRef.current && iframeRef.current.contentWindow) {
      const { document } = iframeRef.current.contentWindow
      document.open()
      document.write('')
      document.close()

      const doc = +club.startdate.substr(0, 4) < new Date().getFullYear() ? 'protokoll-arsmote' : 'protokoll-for-konstituerande-mote'

      if (!doc) {
        setIframeHeight(0)
        return
      }

      fetch(`https://print.schack.se/?document=${doc}&clubid=${club.id}&memdb-url=${process.env.GATSBY_MEMDB_URL}`)
        .then(res => res.text())
        .then(async data => {
          document.open()

          if (doc === 'protokoll-arsmote' && club.active) {
            const match = data.match(/<body[^>]*>((.|[\n\r])*)<\/body>/)
            if (match) {
              const head = data.substr(0, match.index)
              const body = match[1]
              const lastYear = new Date().getFullYear() - 1

              const response = await request<{
                registrations: { member: { birthday: string }; membershiptype: number; data: string }[]
                roles: { orgrole: number; end: string; member: { name: string } }[]
              }>({
                url: `club/${club.id}`,
                params: {
                  schema: {
                    registrations: [
                      {
                        member: {
                          birthday: 1
                        },
                        membershiptype: 1,
                        date: 1,
                        filter: `(&(date>=${lastYear}-01-01)(date<=${lastYear}-12-31)(membershiptype<=119))`
                      }
                    ],
                    roles: [
                      {
                        orgrole: 1,
                        end: 1,
                        member: {
                          name: 1
                        },
                        filter: '(&(orgrole=100)(end=1970-01-01))'
                      }
                    ]
                  }
                }
              })

              if (response && response.data) {
                const { registrations, roles } = response.data

                const year6 = new Date().getFullYear() - 6
                const year25 = new Date().getFullYear() - 25
                const nYouth = registrations.reduce<number>(
                  (n, { member: { birthday } }) => n + (+birthday.substr(0, 4) <= year6 && +birthday.substr(0, 4) >= year25 ? 1 : 0),
                  0
                )

                document.write(`
                ${head}<body>${body}
                  <div document='varksamhetsberattelse'>
                    <h1>Verksamhetsberättelse ${lastYear}</h1>
                    <p>${club.name}</p>

                    <p>Antal medlemmar: ${registrations.length}, därav ${nYouth} i åldrarna 6-25 år.</p>

                    <p>Föreningen är ekonomilös och har inte haft några utgifter eller inkomster under verksamhetsåret.
                    Klubben bedriver schackverksamhet i anknytning till ${club.school ? club.school.name : '<skolnamn>'}.</p>
                    <table style="padding-top: 24px;">
                      <tbody>
                        <tr><td>……………………………….</td></tr>
                        <tr><td>${roles.length ? roles[0].member.name : '<Ordförande>'}</td></tr>
                      </tbody>
                    </table>
                  </div>
                </body></html>`)
              }
            }
          } else {
            document.write(data)
          }
          document.close()
        })
    }
  }, [club])

  const [editAble, setEditAble] = useState(false)
  useEffect(() => {
    if (iframeRef.current && iframeRef.current.contentDocument) {
      iframeRef.current.contentDocument.body.contentEditable = editAble.toString()
    }
  }, [editAble])

  const handleClubChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setClub(clubs.find(({ id }) => id === +event.target.value))
    setEditAble(false)
  }

  const handleIframeLoad = () => {
    if (iframeRef.current && iframeRef.current.contentDocument) {
      setIframeHeight(iframeRef.current.contentDocument.body.offsetHeight + 16)
    }
  }

  const handleSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = event => {
    event.preventDefault()
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.print()

      if (club) {
        const prints = { ...documents, [club.id]: new Date().toLocaleDateString('sv-SE') }
        setStatus({ documents: prints })

        y2cRequest({
          method: 'POST',
          url: '/store',
          data: {
            prints
          }
        })
      }
    }
  }

  const { documentsStatus } = getDocumentsStatus(documents, club ? [club] : [], [
    { id: -1, name: 'fake', groupid: -1, clubid: club ? club.id : -1, roles: [], players: [] }
  ])

  return (
    <form onSubmit={handleSubmit}>
      <TextBubble>Det här är en mall för de handlingar ni ska lämna in. Ni kan själva redigera så att allt blir korrekt.</TextBubble>

      <Card>
        <CardContent>
          <ClassNames>
            {({ css }) => (
              <TextField
                label="Välj klubb"
                value={club ? club.id : ''}
                onChange={handleClubChange}
                select
                fullWidth
                SelectProps={{
                  classes: {
                    root: css({
                      display: 'flex',
                      alignItems: 'center'
                    })
                  }
                }}
              >
                {clubs.map(_club => (
                  <MenuItem key={_club.id} value={_club.id} dense>
                    <ListItemText css={{ margin: 0 }}>{_club.name}</ListItemText>
                    <DocStatus club={_club} />
                  </MenuItem>
                ))}
              </TextField>
            )}
          </ClassNames>
        </CardContent>
      </Card>

      <Card css={{ marginTop: spacing(2), minHeight: 300, position: 'relative', overflow: 'visible' }}>
        {!editAble && (
          <Edit
            onClick={() => setEditAble(true)}
            css={{
              cursor: 'pointer',
              position: 'absolute',
              zIndex: 10,
              top: spacing(10),
              right: -spacing(5),
              padding: spacing(1),
              backgroundColor: 'rgba(0,0,0,0.2)',
              color: '#000'
            }}
          />
        )}
        {documentsStatus.status === 3 && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: green[800],
              color: '#FFF',
              padding: spacing(2),
              borderRadius: '4px 4px 0 0'
            }}
          >
            <Check css={{ marginRight: spacing(0.5), marginTop: -2 }} />
            <Typography>Den här klubben är godkänd</Typography>
          </div>
        )}
        <CardContent>
          {club && (
            <iframe
              ref={iframeRef}
              title="Dokument"
              onLoad={handleIframeLoad}
              css={{
                width: '100%',
                border: 'none',
                height: iframeHeight
              }}
            />
          )}
        </CardContent>
      </Card>
      <ButtonRow>
        <Button type="submit" variant="contained" color="primary" css={{ margin: `${spacing(1)}px 0` }}>
          Skriv ut
        </Button>
        {buttons}
      </ButtonRow>
    </form>
  )
}
