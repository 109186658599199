import { useContext } from 'react'

import { useMemdbRequest, NetworkError } from '.'
import { UserContext } from '../contexts'

function calcErrorMessage(error: NetworkError) {
  if (error.response) {
    switch (error.response.data.error.code) {
      case 2:
        return { email: 'För många medlemmar har samma mailadress', password: '' }
      case 16:
        return { email: 'Ingen mailadress skickades', password: '' }
      case 17:
        return { email: '', password: 'Inget lösenord skickades' }
      case 0:
      case 18:
        return { email: 'Ingen medlem hittades', password: '' }
      case 19:
        return { email: '', password: 'Fel lösenord' }
      default:
        return { email: '', password: '' }
    }
  }
  return { email: 'Något gick fel', password: '' }
}

export const useLogin = () => {
  const { request, error, isLoading } = useMemdbRequest()
  const { token, setToken } = useContext(UserContext)

  const errorMessages: { email?: string; password?: string } = error ? calcErrorMessage(error) : {}

  async function doLogin(email: string, password: string) {
    const [id, loginAs] = email.split(':')

    const response = await request<{ result: string }>({
      url: '/login',
      method: 'POST',
      data: { [+id ? 'id' : 'email']: id, ...(loginAs && { loginAs }), password }
    })

    if (response) {
      setToken(response.data.result)
    }
    return response
  }

  return { token, setToken, doLogin, error, isLoading, errorMessages }
}
