import React from 'react'
import styled, { CreateStyled } from '@emotion/styled'
import { Theme } from '@material-ui/core'

export const FlexRow = (styled as CreateStyled<Theme>).div<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { head?: boolean; hover?: boolean }
>(
  {
    display: 'flex',
    '> div': {
      padding: '6px 8px',

      '&:first-of-type': {
        paddingLeft: 16
      },
      '&:last-child': {
        paddingRight: 16
      }
    },

    '> .flex': {
      flex: '1 1 auto'
    },
    '> .tiny': {
      minWidth: 8
    },
    '> .small': {
      minWidth: 36
    },
    '> .ellipsis': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '> .icon': {
      minWidth: 30,
      padding: '1px 5px',

      '&:first-of-type': {
        paddingLeft: 13
      },
      '&:last-child': {
        paddingRight: 13
      }
    },
    '> .center': {
      textAlign: 'center'
    },

    '.tooltip': {
      cursor: 'default'
    }
  },
  ({ theme, onClick, head, hover }) => ({
    ...theme.typography.body2,
    ...((head && {
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium
    }) || {
      '> div': {
        borderTop: `1px solid ${theme.palette.divider}`
      }
    }),
    ...((!!onClick || hover) && {
      ':hover > div': {
        cursor: 'pointer',
        backgroundColor: theme.palette.action.hover
      }
    })
  })
)
