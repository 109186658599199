/* eslint-disable no-restricted-syntax */
/* eslint-disable no-labels */
import { Button, Container, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { CascadingMenu, Loading, MenuItem, Pairing, Standing } from '..'
import { useUser } from '../../contexts'
import { useMemdbRequest } from '../../hooks'
import { TournamentTableContainer } from './TournamentTableContainer'



interface TournamentProps {
  tournamentId: number
  groupId: number
  round: number
}

interface TournamentResponse {
  name: string
  classes: TournamentClassResponse[]
}

interface TournamentClassResponse {
  id: number
  name: string
  groups: TournamentGroupResponse[]
}

interface TournamentGroupResponse {
  id: number
  name: string
  pairingsystem: number
  currentround: number
}

export interface GroupResponse {
  id: number
  pairingsystem: number
  currentround: number
  topdelimiter: number

  contenders: ContenderResponse[]
  pairings: PairingsResponse[]
}

function isGroupResponse(data: GroupResponse | {}): data is GroupResponse {
  return 'pairingsystem' in data
}
// const isGroupResponse: is GroupResponse = (data: GroupResponse | {}) => 'pairingsystem' in data

interface ContenderResponse {
  team: {
    id: number
    name: string
    school: {
      name: string
    }
  }
  result: {
    place: number
    wins: number
    draws: number
    losses: number
    secpoints: number
    points: number
  }
  withdrawn: number
}

interface PairingsResponse {
  id: number
  finalized: number
  round: number
  awayid: number
  homeid: number
  note: string
  date: string
  awayresult: number
  homeresult: number
}

interface TeamResponse {
  roles: { orgrole: number; member: { id: number; name: string }; email: string; phone: string; end: string }[]
  players: { member: { id: number; name: string } }[]
}

interface MyTeamsResponse {
  roles: {
    orgid: number
    orgrole: number
    orgtype: number
    end: string
    team: Partial<{
      id: number
      name: string
      participations: {
        groupid: number
      }[]
    }>
  }[]
}

const mapGroups = (groups: TournamentGroupResponse[], tournamentId: number) =>
  groups
    .filter(({ name }) => name !== 'Ej inlottade')
    .map<MenuItem>(group => ({
      title: group.name,
      ...(group.pairingsystem === 2 && group.currentround > 0
        ? {
            subMenu: new Array(group.currentround).fill(undefined).map((_, index) => ({
              title: `Rond ${index + 1}`,
              link: `/turneringar?turnering=${tournamentId}&grupp=${group.id}&rond=${index + 1}`
            }))
          }
        : {
            link: `/turneringar?turnering=${tournamentId}&grupp=${group.id}`
          })
    }))
const mapClasses = (classes: TournamentClassResponse[], tournamentId: number) =>
  classes.map<MenuItem>(clazz =>
    clazz.groups.length > 1
      ? {
          title: clazz.name,
          subMenu: mapGroups(clazz.groups, tournamentId)
        }
      : clazz.groups.length
      ? mapGroups(clazz.groups, tournamentId)[0]
      : {
          title: 'Error: no groups'
        }
  )

export const Tournament: React.FC<TournamentProps> = ({ tournamentId, groupId, round }) => {
  const { request: memdbRequest, isLoading } = useMemdbRequest()
  const { request: memdbGroupRequest, isLoading: isGroupLoading } = useMemdbRequest()
  const { request: memdbContenderRequest, isLoading: isContenderLoading } = useMemdbRequest()
  const { request: memdbMyTeamsRequest } = useMemdbRequest()
  const { token } = useUser()

  const [tournament, setTournament] = useState<TournamentResponse>()
  const [group, setGroup] = useState<GroupResponse>()
  const [tournamentMenu, setTournamentMenu] = useState<MenuItem>()
  const [contender, setContender] = useState<ContenderResponse & { team: ContenderResponse['team'] & Partial<TeamResponse> }>()
  const [myTeamsMenu, setMyTeamsMenu] = useState<MenuItem>()

  useEffect(() => {
    if (tournamentId) {
      memdbRequest<TournamentResponse>({
        url: `/tournament/${tournamentId}`,
        params: {
          schema: { name: 1, classes: [{ name: 1, groups: [{ id: 1, name: 1, pairingsystem: 1, currentround: 1, sort: [['id']] }] }] }
        }
      }).then(response => {
        if (response && response.data) {
          if (!groupId) {
            const classes = response.data.classes.filter(clazz => clazz.name !== response.data.name)
            if (classes.length) {
              navigate(`${window.location.pathname}?turnering=${tournamentId}&grupp=${classes[0].groups[0].id}`, {
                replace: true
              })
            }
          }
          setTournament(response.data)
        }
      })
    }
  }, [tournamentId])

  useEffect(() => {
    if (groupId) {
      memdbGroupRequest<GroupResponse | {}>({
        url: `/tournament/group/${groupId}`,
        params: {
          schema: {
            id: 1,
            pairingsystem: 1,
            currentround: 1,
            topdelimiter: 1,
            contenders: [
              {
                team: { id: 1, name: 1, school: { name: 1 } },
                result: { place: 1, wins: 1, draws: 1, losses: 1, secpoints: 1, points: 1 },
                withdrawn: 1,
                sort: [['result.place', 'asc']],
                filter: '(result=*)'
              }
            ],

            pairings: [
              {
                id: 1,
                finalized: 1,
                round: 1,
                awayid: 1,
                homeid: 1,
                note: 1,
                date: 1,
                awayresult: 1,
                homeresult: 1
              }
            ]
          }
        }
      }).then(response => {
        if (response && response.data && isGroupResponse(response.data)) {
          setGroup(response.data)
        }
      })
    }
  }, [groupId])

  useEffect(() => {
    if (tournament && groupId) {
      let title = tournament.name

      root: for (const clazz of tournament.classes) {
        for (const grp of clazz.groups) {
          if (grp.id === groupId) {
            title += `: ${grp.name}`
            break root
          }
        }
      }

      title += round ? ` ${round}` : ''

      const classes = tournament.classes.filter(clazz => clazz.name !== tournament.name)
      setTournamentMenu({
        title,
        Component: Button,
        menuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        },
        subMenu: classes.length ? (classes.length > 1 ? mapClasses(classes, tournamentId) : mapGroups(classes[0].groups, tournamentId)) : []
      })
    }
  }, [tournament, groupId, round])

  useEffect(() => {
    if (group?.id === groupId) {
      if (group.pairingsystem === 2 && !round) {
        navigate(`${window.location.pathname}?turnering=${tournamentId}&grupp=${groupId}&rond=${group.currentround || 1}`, {
          replace: true
        })
      } else if (group.pairingsystem !== 2 && round) {
        navigate(`${window.location.pathname}?turnering=${tournamentId}&grupp=${groupId}`, {
          replace: true
        })
      }
    }
  }, [group, round])

  useEffect(() => {
    if (tournament && token) {
      memdbMyTeamsRequest<MyTeamsResponse>({
        url: '/member/me',
        params: {
          schema: {
            roles: [
              {
                orgid: 1,
                orgrole: 1,
                orgtype: 1,
                team: { id: 1, name: 1, participations: [{ groupid: 1 }] },
                end: 1,
                filter: '(&(&(orgrole=201)(end=1970-01-01))(orgtype=4))'
              }
            ]
          }
        }
      }).then(response => {
        if (response && response.data) {
          const subMenu: (MenuItem & { name: string; classId: number; groupId: number })[] = []

          for (const {
            team: { name, participations }
          } of response.data.roles) {
            for (const clazz of tournament.classes.filter(({ name: className }) => className !== tournament.name)) {
              if (participations && name) {
                const grp = clazz.groups.find(({ id }) => participations.some(({ groupid }) => groupid === id))
                if (grp) {
                  subMenu.push({
                    title: `${grp.name}: ${name}`,
                    link: `/turneringar?turnering=${tournamentId}&grupp=${grp.id}`,
                    name,
                    classId: clazz.id,
                    groupId: grp.id
                  })
                }
              }
            }
          }
          setMyTeamsMenu({
            title: 'Mina lag',
            Component: Button,
            menuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              }
            },
            subMenu: subMenu.sort((a, b) =>
              a.name !== b.name
                ? // eslint-disable-next-line no-bitwise
                  (+(a.name > b.name) << 1) - 1
                : a.classId !== b.classId
                ? // eslint-disable-next-line no-bitwise
                  (+(a.classId < b.classId) << 1) - 1
                : // eslint-disable-next-line no-bitwise
                  (+(a.groupId < b.groupId) << 1) - 1
            )
          })
        }
      })
    } else {
      setMyTeamsMenu(undefined)
    }
  }, [tournament, token])

  function handleKeyDown({ key }: KeyboardEvent) {
    if ((key === 'ArrowRight' || key === 'ArrowLeft') && tournament) {
      for (const clazz of tournament.classes) {
        const index = clazz.groups.findIndex(({ id }) => id === groupId)
        if (index >= 0) {
          const grp = clazz.groups[index]
          if (grp.pairingsystem === 2) {
            if (key === 'ArrowRight' && round < grp.currentround) {
              navigate(`${window.location.pathname}?turnering=${tournamentId}&grupp=${grp.id}&rond=${round + 1}`)
              return
            }
            if (key === 'ArrowLeft' && round > 1) {
              navigate(`${window.location.pathname}?turnering=${tournamentId}&grupp=${grp.id}&rond=${round - 1}`)
              return
            }
          }
          if (key === 'ArrowRight' && index < clazz.groups.length - 1) {
            navigate(`${window.location.pathname}?turnering=${tournamentId}&grupp=${clazz.groups[index + 1].id}`)
            return
          }
          if (key === 'ArrowLeft' && index > 0) {
            navigate(`${window.location.pathname}?turnering=${tournamentId}&grupp=${clazz.groups[index - 1].id}`)
            return
          }
          return
        }
      }
    }
  }

  const handleContenderClick = (cont: ContenderResponse) => (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (token) {
      memdbContenderRequest<{ team: TeamResponse }>({
        url: `/tournament/group/${groupId}/contender/${cont.team.id}/teamnumber/1`,
        params: {
          schema: {
            team: {
              roles: [{ orgrole: 1, member: { id: 1, name: 1 }, email: 1, phone: 1, end: 1, filter: '(&(orgrole=201)(end=1970-01-01))' }],
              players: [{ member: { id: 1, name: 1 } }]
            }
          }
        }
      }).then(response => {
        if (response && response.data) {
          setContender({ ...cont, team: { ...cont.team, ...response.data.team } })
        }
      })
    } else {
      setContender(cont)
    }
  }

  const handleClose = () => {
    setContender(undefined)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <>
      {tournamentMenu && (
        <Helmet>
          <title>{`${tournamentMenu.title?.replace(':', ' -')}`}</title>
        </Helmet>
      )}
      {isLoading ? (
        <Loading
          css={{
            height: 'calc(100vh - 64px)',
            background: 'initial'
          }}
        />
      ) : (
        tournamentMenu && (
          <Container maxWidth="md" css={{ marginTop: 100 }}>
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8 }}>
              <CascadingMenu menu={tournamentMenu} />
              {myTeamsMenu &&
                (myTeamsMenu.subMenu && myTeamsMenu.subMenu.length ? (
                  <CascadingMenu menu={myTeamsMenu} />
                ) : (
                  <Typography variant="caption">Du har inga lag i den här turneringen</Typography>
                ))}
            </div>
            <TournamentTableContainer isLoading={isGroupLoading}>
              {group ? <Standing group={group} onContenderClick={handleContenderClick} /> : 'Något gick fel'}
            </TournamentTableContainer>
            <TournamentTableContainer isLoading={isGroupLoading}>
              {group ? <Pairing group={group} round={round} onContenderClick={handleContenderClick} /> : 'Något gick fel'}
            </TournamentTableContainer>

            <Dialog onClose={handleClose} aria-labelledby="contender-dialog" open={!!contender || isContenderLoading}>
              {isContenderLoading && (
                <div
                  css={{
                    width: 423,
                    height: 200
                  }}
                >
                  <Loading />
                </div>
              )}
              {contender && (
                <DialogContent>
                  <Typography gutterBottom>Skola: {contender.team.school.name}</Typography>
                  {contender.team.roles && contender.team.players ? (
                    <>
                      <Typography variant="subtitle2">Lagledare</Typography>
                      {contender.team.roles.map((role, index, arr) => (
                        <Typography key={role.member.id} gutterBottom={index === arr.length - 1}>
                          {role.member.name} <a href={`mailto:${role.email}`}>{role.email}</a> <a href={`tel:${role.phone}`}>{role.phone}</a>
                        </Typography>
                      ))}

                      <Typography variant="subtitle2">Spelare</Typography>
                      {contender.team.players.map(player => (
                        <Typography key={player.member.id}>{player.member.name}</Typography>
                      ))}
                    </>
                  ) : (
                    <Typography>Du måste logga in för att kunna se lagets uppgifter.</Typography>
                  )}
                </DialogContent>
              )}
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  Stäng
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        )
      )}
    </>
  )
}
