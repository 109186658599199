import React, { DOMAttributes, useContext, useRef, useState } from 'react'
import { Card, Button, useTheme, Theme, List, ListItem, ListItemText, TextField, MenuItem, Divider } from '@material-ui/core'
import { blue, green, yellow } from '@material-ui/core/colors'
import { Print, Person } from '@material-ui/icons'

import { ButtonRow, Loading, TextBubble } from '..'
import { UserContext, useRegStatus, Club, Team } from '../../contexts'
import { useMemdbRequest } from '../../hooks'

type Registration = NonNullable<Club['registrations']>[0]

const filterFn = (clubs: Club[], filter: number, teams: Team[]): Club[] => {
  if (filter > 0) {
    const club = clubs.find(({ id }) => id === filter)
    return club ? [club] : []
  }

  if (filter === -3) {
    return clubs
  }

  const players: { [key: string]: boolean } = teams.reduce(
    (obj, team) => ({
      ...obj,
      ...team.players.reduce((obj2, player) => ({ ...obj2, [`${team.clubid}-${player.member.id}`]: true }), {})
    }),
    {}
  )

  const fn: (regs: Registration[], clubid: number) => Registration[] =
    filter === -1
      ? (regs, clubid) => regs.filter(reg => !!players[`${clubid}-${reg.member.id}`])
      : regs => regs.filter(({ member }) => member.birthday > `${new Date().getFullYear() - 25}-01-01`)

  return clubs.map(club => ({
    ...club,
    registrations: fn(club.registrations || [], club.id)
  }))
}

export function RegVerifications({ buttons }: { buttons: React.ReactNode }) {
  const { token } = useContext(UserContext)
  const {
    status: { clubs = [], teams = [] },
    setStatus
  } = useRegStatus()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { spacing } = useTheme<Theme>()
  const [isLoading, setLoading] = useState(false)
  const { request } = useMemdbRequest()
  const [filter, setFilter] = useState(-1)

  const updateMembership = (clubid: number, reg: Registration) =>
    reg.membershiptype >= 120
      ? request<{ result: number }>({
          method: 'POST',
          url: `club/${clubid}/membership/${reg.member.id}`,
          data: {
            reg: { ...reg, clubid, memberid: reg.member.id, fee: -1, member: undefined },
            oldreg: { ...reg, clubid, memberid: reg.member.id, member: undefined }
          }
        }).then(response => {
          if (response && response.data && response.data.result === 1) {
            // eslint-disable-next-line no-param-reassign
            reg.fee = -1
            setStatus({ clubs: [...clubs] })
          }
        })
      : undefined

  const getMemberVerification = (clubid: number, members: number[]) =>
    members.length
      ? fetch(
          `https://print.schack.se/?document=medlemslapp&clubid=${clubid}${members.reduce(
            (prev, value) => `${prev}&memberid=${value}`,
            ''
          )}&token=${token}&memdb-url=${process.env.GATSBY_MEMDB_URL}`
        ).then(res => res.text())
      : ''

  const print = (data: string) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const { document } = iframeRef.current.contentWindow
      document.open()
      document.write(data)
      document.close()
    }
  }

  const handleIframeLoad = () => {
    setTimeout(() => {
      const iframe = iframeRef.current
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.print()
        setLoading(false)
      }
    }, 200)
  }

  const handleFilterChanged = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(+target.value)
  }

  const handleMemberClick = (clubid: number, reg: Registration) => async () => {
    setLoading(true)
    updateMembership(clubid, reg)
    print(await getMemberVerification(clubid, [reg.member.id]))
  }

  const handleSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = event => {
    event.preventDefault()

    setLoading(true)
    const filteredClubs = filterFn(clubs, filter, teams)
    Promise.all(
      filteredClubs.map(({ id, registrations }) =>
        getMemberVerification(
          id,
          (registrations || []).filter(({ membershiptype }) => membershiptype >= 120).map(({ member }) => member.id)
        )
      )
    ).then(responses => {
      const [head, body] = responses.reduce<[string, string]>(
        ([_head, _body], verification) => {
          const match = verification.match(/<body[^>]*>((.|[\n\r])*)<\/body>/)
          return match ? [verification.substr(0, match.index), _body + match[1]] : [_head, _body]
        },
        ['', '']
      )

      print(`${head + body}</body></html>`)

      filteredClubs.forEach(club => (club.registrations || []).forEach(reg => updateMembership(club.id, reg)))
    })
  }

  const noRegs = !filterFn(clubs, filter, teams).some(club => club.registrations && club.registrations.length > 0)

  return (
    <form onSubmit={handleSubmit}>
      <iframe
        title="Medlemsintygande"
        ref={iframeRef}
        onLoad={handleIframeLoad}
        css={{
          height: 0,
          border: 'none',
          visability: 'hidden'
        }}
      ></iframe>
      <TextBubble>
        {/* eslint-disable-next-line max-len */}
        Skriv ut och skriv under medlemsintyganden. Skicka dem sedan till <a href="mailto:yes2chess@schack.se">yes2chess@schack.se</a> eller
        Sveriges Schackförbund, c/o UNT BOX 36 751&nbsp;03 Uppsala.
        <br />
        Det är endast spelare som behöver godkännas för deltagande i Yes2Chess
      </TextBubble>
      <Card css={{ position: 'relative', marginTop: spacing(2) }}>
        <div css={{ padding: 16 }}>
          <TextField value={filter} onChange={handleFilterChanged} select fullWidth>
            <MenuItem value={-1}>Alla spelare</MenuItem>
            <MenuItem value={-2}>Alla ungdomsmedlemmar</MenuItem>
            <MenuItem value={-3}>Alla medlemmar</MenuItem>
            <Divider />
            {clubs.length > 1 &&
              clubs.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </Card>
      <Card css={{ position: 'relative', marginTop: spacing(2) }}>
        <List
          css={{
            maxHeight: 408,
            overflow: 'auto'
          }}
        >
          {filterFn(clubs, filter, teams).map(
            club =>
              club.registrations &&
              club.registrations.map(reg => (
                <ListItem key={`${club.id}-${reg.member.id}`} button dense onClick={handleMemberClick(club.id, reg)}>
                  <Person
                    css={{ marginRight: spacing(1), color: reg.membershiptype < 120 ? green[800] : reg.fee < 0 ? blue[800] : yellow[800] }}
                  />
                  <ListItemText>
                    {reg.member.firstname} {reg.member.lastname}
                  </ListItemText>
                  <Print />
                </ListItem>
              ))
          )}
          {noRegs && (
            <ListItem dense>
              <ListItemText css={{ fontStyle: 'italic' }}>Inga medlemmar hittades, testa att välja ett nytt filter ovan</ListItemText>
            </ListItem>
          )}
        </List>
        {isLoading && <Loading />}
      </Card>
      <ButtonRow>
        <Button type="submit" disabled={noRegs} variant="contained" color="primary" css={{ margin: `${spacing(1)}px 0` }}>
          Skriv ut
        </Button>
        {buttons}
      </ButtonRow>
    </form>
  )
}
