import React, { useEffect, useState, useContext } from 'react'
import { Tournament } from 'y2c-helpers'

import { useMemdbRequest } from '../hooks'

export interface ITournamentsContext {
  tournaments?: Tournament[]
  isLoading: boolean
}

export const TournamentsContext = React.createContext<ITournamentsContext>({
  tournaments: [],
  isLoading: false
})

export const TournamentsContextProvider: React.FC<{ tournamentid: string }> = ({ tournamentid, ...props }) => {
  const { request } = useMemdbRequest()
  const [isLoading, setLoading] = useState(false) // can't use useMemdbRequest? request array...
  const [tournaments, setTournaments] = useState<Tournament[]>()

  useEffect(() => {
    setLoading(true)
    Promise.all(
      tournamentid.split(',').map(tid =>
        request<Tournament>({
          url: `/tournament/${tid}`,
          params: { schema: { id: 1, name: 1, classes: [{ id: 1, name: 1, groups: [{ id: 1, name: 1, sort: [['id']] }] }] } }
        })
      )
    )
      .then(responses => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setTournaments(responses.filter(response => !!response).map(response => response!.data))
        setLoading(false)
      })
      .catch(e => {
        alert(e.message)
        setLoading(false)
      })
  }, [])

  return <TournamentsContext.Provider value={{ tournaments, isLoading }} {...props} />
}

export const useTournaments = () => useContext(TournamentsContext)
