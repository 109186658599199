import React, { useState } from 'react'
import { MenuItem, Divider, CardContent, Grid } from '@material-ui/core'
import TextField, { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField'
import { MuiPickersUtilsProvider, KeyboardDatePickerProps, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import svLocale from 'date-fns/locale/sv'
import * as yup from 'yup'
import { parsePhoneNumber } from 'libphonenumber-js'
import { Password } from '.'

export type Schema = ReturnType<typeof yup.string | typeof yup.number | typeof yup.date>

yup.setLocale({
  mixed: {
    required: 'Tomt fält',
    typeError: 'Fältet måste vara av rätt typ'
  },
  number: {
    typeError: 'Fältet måste vara ett nummer på 5 siffror',
    min: 'Fältet måste innehålla 5 siffror',
    max: 'Fältet måste innehålla 5 siffror'
  },
  string: {
    email: 'Måste vara en giltig mailadress'
  },
  date: {
    typeError: 'Du måste ange ett giltigt datum i formatet yyyy-mm-dd'
  }
})

interface BaseFieldProps {
  schema: {
    soft: Schema
    medium?: Schema
    hard?: Schema
  }
}

export type TextFieldProps = BaseFieldProps & {
  type?: 'password'
  props: MUITextFieldProps
}

export type DateFieldProps = BaseFieldProps & {
  type: 'date'
  props: KeyboardDatePickerProps
}

export type FieldProps = TextFieldProps | DateFieldProps

export const firstname: TextFieldProps = {
  props: { name: 'firstname', label: 'Förnamn', autoFocus: true },
  schema: {
    soft: yup.string(),
    medium: yup.string().required()
  }
}

export const lastname: TextFieldProps = {
  props: { name: 'lastname', label: 'Efternamn' },
  schema: {
    soft: yup.string(),
    medium: yup.string().required()
  }
}

export const birthday: DateFieldProps = {
  type: 'date',
  props: {
    name: 'birthday',
    label: 'Födelsedag',
    variant: 'inline',
    format: 'yyyy-MM-dd',
    placeholder: 'yyyy-mm-dd',
    minDate: `${new Date().getFullYear() - 120}-01-01`,
    maxDate: `${new Date().getFullYear() - 4}-12-31`,
    KeyboardButtonProps: {
      tabIndex: -1
    },
    value: null,
    onChange: () => {}
  },
  schema: {
    soft: yup.date(),
    medium: yup
      .date()
      .typeError('')
      .required(),
    hard: yup
      .date()
      .transform((_value, originalvalue) => new Date(originalvalue))
      .required()
      .typeError('Ange ett datum enligt formatet yyyy-mm-dd')
      // eslint-disable-next-line no-template-curly-in-string
      .min(`${new Date().getFullYear() - 120}-01-01`, 'Valt datumet måste vara efter ${min}')
      // eslint-disable-next-line no-template-curly-in-string
      .max(`${new Date().getFullYear() - 4}-12-31`, 'Valt datumet måste vara innan ${max}')
  }
}

export const sex: TextFieldProps = {
  props: {
    name: 'sex',
    label: 'Kön',
    select: true,
    children: ['Man', 'Kvinna'].map((label, value) => (
      // eslint-disable-next-line react/no-array-index-key
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))
  },
  schema: {
    soft: yup.number(),
    medium: yup
      .number()
      .required()
      .min(0, 'Välj man eller kvinna')
  }
}

export const street: TextFieldProps = {
  props: { name: 'street', label: 'Gatuadress' },
  schema: {
    soft: yup.string(),
    medium: yup.string().required()
  }
}

export const zipcode: TextFieldProps = {
  props: { name: 'zipcode', label: 'Postnummer' },
  schema: {
    soft: yup.number(),
    medium: yup
      .number()
      .required()
      .max(99999),
    hard: yup
      .number()
      .required()
      .min(10000)
      .max(99999)
  }
}

export const city: TextFieldProps = {
  props: { name: 'city', label: 'Ort' },
  schema: {
    soft: yup.string(),
    medium: yup.string().required()
  }
}

export const phone: TextFieldProps = {
  props: { name: 'phone', label: 'Telefonnummer' },
  schema: {
    soft: yup.string(),
    medium: yup.string().required(),
    hard: yup
      .string()
      .required()
      .test(
        'test-phonenumber',
        'Du måste ange ett korrekt svenskt telefonnummer',
        value => value && parsePhoneNumber(value, 'SE').isValid()
      )
  }
}

export const email: TextFieldProps = {
  props: { name: 'email', label: 'Email' },
  schema: {
    soft: yup.string(),
    medium: yup.string().required(),
    hard: yup
      .string()
      .email()
      .required()
  }
}

export const password: TextFieldProps = {
  type: 'password',
  props: { name: 'password', label: 'Lösenord' },
  schema: {
    soft: yup.string(),
    medium: yup.string().required()
  }
}

export function MemberFields<T extends string>({
  fields,
  values,
  errors,
  onDateChange,
  ...props
}: {
  fields: FieldProps[][]
  values: { [P in T]?: string | number | Date | null }
  errors: { [P in T]?: string }
  onDateChange: KeyboardDatePickerProps['onChange']
} & Pick<MUITextFieldProps, 'onChange' | 'onBlur'>) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={svLocale}>
      {fields.map((container, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index !== 0 && <Divider />}
          <CardContent>
            <Grid container spacing={3}>
              {container.map(field => {
                const name = (field.props.name as unknown) as T

                const textFieldProps = {
                  fullWidth: true,
                  value: values[name] === undefined ? '' : values[name],
                  error: !!errors[name],
                  helperText: errors[name],
                  ...props
                }

                return (
                  <Grid key={name} item xs={12} sm={6}>
                    {field.type === 'date' ? (
                      <KeyboardDatePicker
                        {...field.props}
                        {...textFieldProps}
                        value={values[name] === undefined ? null : values[name]}
                        onChange={onDateChange}
                      />
                    ) : field.type === 'password' ? (
                      <Password {...textFieldProps} {...field.props} />
                    ) : (
                      <TextField {...textFieldProps} {...field.props} />
                    )}
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </React.Fragment>
      ))}
    </MuiPickersUtilsProvider>
  )
}

export function useMemberFields<T>(member: { [P in keyof T]?: string | number | Date | null }, obj: { [P in keyof T]: BaseFieldProps }) {
  const [values, setValues] = useState(member)
  const [errors, setErrors] = useState<{ [P in keyof T]?: string }>({})

  async function validate(newValues: typeof values, schema: 'soft' | 'medium' | 'hard') {
    const retValues: typeof values = { ...values }
    const retErrors: typeof errors = { ...errors }
    await Promise.all(
      Object.entries(newValues).map(async entry => {
        const [key, value] = entry as [keyof typeof values, string | number | Date | null | undefined]
        if (obj[key]) {
          const schemaObj = obj[key].schema

          try {
            retValues[key] = await (schemaObj[schema] || schemaObj.hard || schemaObj.medium || schemaObj.soft).validate(value)
            retErrors[key] = undefined
          } catch (validationError) {
            retValues[key] = undefined
            retErrors[key] = validationError.message
          }
        }
      })
    )
    return { values: retValues, errors: retErrors }
  }

  const onChange: MUITextFieldProps['onChange'] = async event => {
    // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
    const newValue = { [event.target.name]: event.target.value } as typeof values
    setValues({ ...values, ...newValue })
    setErrors((await validate(newValue, 'medium')).errors)
  }

  const onDateChange: KeyboardDatePickerProps['onChange'] = async (_date, value) => {
    const newValue = { birthday: value || undefined }
    setValues({ ...values, ...newValue })
    setErrors((await validate(newValue, 'medium')).errors)
  }

  async function onBlur() {
    setErrors((await validate(values, 'hard')).errors)
  }

  return { values, setValues, errors, setErrors, validate, onChange, onDateChange, onBlur }
}
