import React from 'react'
import { Card, CardContent, TextField, Button, useTheme, Theme } from '@material-ui/core'

import { Password, Loading, ButtonRow, RecoverPassword } from '.'
import { useLogin } from '../hooks'

export interface LoginCardProps {
  email?: string
  buttons?: React.ReactNode
}

export const LoginCard: React.FC<LoginCardProps> = ({ email = '', buttons }) => {
  const { doLogin, isLoading, errorMessages } = useLogin()
  const { spacing } = useTheme<Theme>()

  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement> & { target: { email: HTMLInputElement; password: HTMLInputElement } }
  ) {
    event.preventDefault()

    await doLogin(event.target.email.value, event.target.password.value)
  }

  return (
    <form onSubmit={handleSubmit} css={{ maxWidth: 600, margin: '0 auto' }}>
      <Card css={{ position: 'relative' }}>
        <CardContent>
          <TextField
            defaultValue={email}
            name="email"
            autoFocus={!email}
            margin="dense"
            label="Email eller medlemsid"
            type="text"
            fullWidth
            error={!!errorMessages.email}
            helperText={errorMessages.email}
          />
          <Password autoFocus={!!email} label="Lösenord" error={!!errorMessages.password} helperText={errorMessages.password} />
        </CardContent>
        {isLoading && <Loading />}
      </Card>
      <ButtonRow css={{ margin: `${spacing(1)}px 0` }}>
        <Button type="submit" disabled={isLoading} variant="contained" color="primary">
          Logga in
        </Button>
        <RecoverPassword email={email} />
        {buttons}
      </ButtonRow>
    </form>
  )
}
