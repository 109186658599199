import styled from '@emotion/styled'

const transparent = 'rgba(0, 0, 0, 0)'
const background = 'white'
const shadow = 'black'

export const ScrollShadow = styled.div({
  overflow: 'auto',
  background: `
		linear-gradient(to right, ${background} 16px, ${transparent}),
		linear-gradient(to right, ${transparent}, ${background} 80%) 0 calc(100% - 16px),
    linear-gradient(to right, ${shadow}, ${transparent} 16px),
    linear-gradient(to left, ${shadow}, ${transparent} 16px);
  `,
  backgroundAttachment: 'local, local, scroll, scroll'
})
