import React, { useState } from 'react'
import { AppBar, Toolbar, Button, Typography, useTheme, IconButton, MenuItem as MUIMenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { ClassNames } from '@emotion/core'
// import { SignalWifi4Bar } from '@material-ui/icons'
import { Link } from 'gatsby'

import { Tournament, Page } from '../lib/createPages'

import { LoginDialog, CascadingMenu, Theme } from '.'
import { MenuItem } from './CascadingMenu'
import { useUser } from '../contexts'

interface HeaderProps {
  pageContext: { tournament: Tournament; edges: { node: Page }[] }
}

export function Header({ pageContext }: HeaderProps) {
  const theme = useTheme<Theme>()
  const { token, setToken } = useUser()
  const [isLoginDialogOpen, setLoginDialog] = useState(false)

  function toggleLogin() {
    if (token) {
      setToken(undefined)
      setLoginDialog(false)
    } else {
      setLoginDialog(!isLoginDialogOpen)
    }
  }

  const tournamentSubMenu = pageContext.tournament.acf.tournament_menu.reduce<MenuItem[]>((menu, { title, link, ...item }) => {
    const parent = parseInt(item.wordpress_parent, 10) - 1
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parent)) {
      return [
        ...menu,
        {
          title,
          link
        }
      ]
    }

    // eslint-disable-next-line no-param-reassign
    menu[parent].subMenu = [
      ...(menu[parent].subMenu || []),
      {
        title,
        link
      }
    ]
    return menu
  }, [])

  const tournamnetMenu: MenuItem<React.ComponentProps<typeof Button>> = {
    Component: Button,
    componentProps: () => ({ color: 'inherit' }),
    menuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: -8
      }
    },
    title: 'Turneringar',
    subMenu: tournamentSubMenu
  }

  const links: MenuItem[] = [
    // { link: '/anmalan', title: 'Anmälan' },
    ...pageContext.edges.map(({ node }) => ({ link: `/${node.slug}`, title: node.title }))
  ]

  const loginItem: MenuItem<React.ComponentProps<typeof MUIMenuItem>> = {
    title: token ? 'Logga ut' : 'Logga in',
    componentProps: ({ onCloseAll }) => ({
      onClick() {
        onCloseAll()
        toggleLogin()
      }
    })
  }

  const mobileMenu: MenuItem<React.ComponentProps<typeof IconButton>> = {
    Component: IconButton,
    componentProps: () => ({
      color: 'inherit',
      size: 'small',
      children: <MoreVert />,
      css: { [theme.breakpoints.up('md')]: { display: 'none' } }
    }),
    subMenu: [...links, loginItem]
  }

  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar>
        <ClassNames>
          {({ css, cx }) => {
            const hiddenSm = css({
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              }
            })
            const buttonStyle = cx(
              css({
                color: 'inherit',
                textDecoration: 'none',
                margin: '0 8px'
              }),
              hiddenSm
            )
            return (
              <>
                <Typography variant="h6">
                  <Link to="/" css={{ color: 'inherit', textDecoration: 'none' }}>
                    {pageContext.tournament.title}
                  </Link>
                </Typography>
                <div css={{ flexGrow: 1 }} />

                <CascadingMenu menu={tournamnetMenu} />

                {links.map(({ link, title }) => (
                  <Link key={link} to={link || ''} className={buttonStyle}>
                    <Button color="inherit">{title}</Button>
                  </Link>
                ))}
                <div className={hiddenSm} css={{ flexGrow: 1 }} />
                <Button onClick={toggleLogin} className={buttonStyle}>
                  {token ? 'Logga ut' : 'Logga in'}
                </Button>

                <CascadingMenu menu={mobileMenu} />
              </>
            )
          }}
        </ClassNames>
        <LoginDialog open={isLoginDialogOpen} onClose={toggleLogin} />
      </Toolbar>
    </AppBar>
  )
}
