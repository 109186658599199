import { Global } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import { createMuiTheme, Dialog, DialogContent, Typography, DialogActions, Button } from '@material-ui/core'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import { Helmet } from 'react-helmet'

import { playingYear } from 'y2c-helpers'
import { Tournament } from '../lib/createPages'
import { useY2CRequest } from '../hooks'
import { useWp } from '../contexts'

const defaultTheme = createMuiTheme()
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C0175D'
    },
    secondary: {
      main: '#8FBF21'
    }
  },

  typography: {
    h1: {
      ...defaultTheme.typography.h2,
      fontWeight: 500,
      fontSize: 42
    },
    h2: {
      ...defaultTheme.typography.h6
    }
  },

  overrides: {
    MuiListSubheader: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)'
      }
    }
  }
})
export type Theme = typeof theme

export const Layout: React.FC<{ pageContext: { tournament: Tournament } }> = ({ children, pageContext }) => {
  const [dialogText, setDialogText] = useState('')
  const { request } = useY2CRequest()
  const { category } = useWp()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location.href)
      const unsubscribe = url.searchParams.get('unsubscribe')
      if (unsubscribe) {
        url.searchParams.delete('unsubscribe')
        window.history.replaceState({}, document.title, url.toString())

        setDialogText('Vi har nu registrerat att du inte ska ha några automatiska mail angående din anmälan till turneringen')

        request({
          method: 'POST',
          url: '/status',
          headers: { Authorization: `Bearer ${unsubscribe}` },
          data: { tournament: `${category.slug.toUpperCase()}-${playingYear}`, emailSubscribe: 'unsubscribed' }
        })
      }

      const unregister = url.searchParams.get('unregister')
      if (unregister) {
        url.searchParams.delete('unregister')
        window.history.replaceState({}, document.title, url.toString())

        setDialogText('Vi har nu plockat bort dig från turneringen')

        request({
          method: 'POST',
          url: '/status',
          headers: { Authorization: `Bearer ${unregister}` },
          data: { tournament: `${category.slug.toUpperCase()}-${playingYear}`, removed: true }
        })
      }
    }
  }, [])

  const handleCloseDialog = () => {
    setDialogText('')
  }

  return (
    <MUIThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <Global
          styles={{
            body: {
              margin: 0
            }
          }}
        />
        <div>
          <div
            css={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundImage: `url(${pageContext.tournament.featured_media.source_url})`,
              backgroundSize: 'cover',
              backgroundPosition: '50% 0',
              backgroundRepeat: 'no-repeat',
              filter: 'blur(20px)',
              opacity: 0.4
            }}
          />
          <div css={{ position: 'relative' }}>{children}</div>
        </div>
        <Dialog open={!!dialogText} onClose={handleCloseDialog}>
          <DialogContent>
            <Typography>{dialogText}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Stäng</Button>
          </DialogActions>
        </Dialog>
      </EmotionThemeProvider>
    </MUIThemeProvider>
  )
}
