import React from 'react'
import { CookiesProvider } from 'react-cookie'

import { ResetPassword, Layout } from '.'
import { UserContextProvider } from '../contexts'
import { Tournament } from '../lib/createPages'

export const Root: React.FC<{ pageContext: { tournament: Tournament } }> = ({ pageContext, children }) => (
  <CookiesProvider>
    <UserContextProvider>
      <ResetPassword />
      <Layout pageContext={pageContext}>{children}</Layout>
    </UserContextProvider>
  </CookiesProvider>
)
