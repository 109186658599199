import React from 'react'
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel'
import { useRegStatus, useWp } from '../contexts'

interface Question {
  title: string
  body: string | JSX.Element
  expansionPanelProps?: ExpansionPanelProps
}

export const Faq: React.FC = () => {
  const { page } = useRegStatus()
  const { category } = useWp()

  const questions: { [key: string]: Question[] | undefined } = {
    overview: [
      {
        title: 'Vad betyder färgerna?',
        body: (
          <>
            Grå: Inaktiverad
            <br />
            Gul: Här har du saker kvar att göra
            <br />
            Blå: Skicka in papper och vänta på att vi hör av oss.
            <br />
            Grön: Allt klart i den har sektionen
          </>
        ),
        expansionPanelProps: { defaultExpanded: true }
      },
      {
        title: 'Hur många medlemmar ska jag ha per klubb?',
        body:
          'Fem barn spelar vid varje match. Av dessa ska minst en vara pojke/flicka. Klubben bör dock ha fler än fem medlemmar så att det finns marginaler vid exempelvis sjukdom. Observera att alla spelare som är anmälda för spel i tävlingen är valbara för spel i alla matcher.'
      },
      {
        title: 'Vem får ingå i styrelsen?',
        body:
          'En styrelse består av minst tre ledamöter. Ordförande, sekreterare och övrig ledamot. Ordförande ska vara en vuxen anställd på skolan.'
      },
      {
        title: 'Under vilken period är anmälan öppen?',
        body: category.slug.toUpperCase() === 'SMS' ? '1/11-30/11':(
          <>
            Årskurs 5: 1/10-15/11
            <br />
            Årskurs 6: 15/10-15/11
            <br />
            Årskurs 7: 15/10-15/11
            <br />
          </>
        )
      }
    ],

    account: [
      {
        title: 'Vilket telefonnummer ska jag uppge?',
        body:
          'Det ska vara ett mobilnummer där du är anträffbar. Det är viktigt att lagledarna kan få kontakt med varandra för att boka matchtid och vid själva matchen.'
      },
      {
        title: 'Vilka lösenordsregler gäller?',
        body: 'Lösenordet ska vara minst 5 tecken långt.'
      }
    ],

    school: [
      {
        title: 'Jag hittar inte min skola, hur gör jag då?',
        body:
          'Skolnamnen är hämtade från Skolverket, vilket innebär att det är skolans officiella namn som ska anges. Om det är en nystartad skola kan det hända att vi saknar uppgiften och då måste ni kontakta oss.'
      },
      {
        title: 'Varför måste vi ange sport- och påsklov?',
        body: 'Av praktiska skäl lottar vi i görligaste mån ihop er med skolor som har samma lovveckor.'
      }
    ],

    clubs: [
      {
        title: 'Vad är "Andra klubbar på skolan"?',
        body:
          'Det kan finnas skolschackklubbar vid din skola sedan tidigare. Kanske kan barnen tävla för den klubben och då behöver ingen ny klubb bildas.'
      }
    ],

    teams: [
      {
        title: 'Är inte klubben redan anmäld till tävlingen?',
        body:
          'Klubben är bildad, men vi måste också få veta i vilken tävling den ska vara med och vilka klubbmedlemmar som ska spela för klubben.'
      }
    ],

    documents: [
      {
        title: 'Vad ska finnas i en verksamhetsberättelse?',
        body:
          'I en verksamhetsberättelse brukar man framför allt ha en beskrivning av den verksamhet som bedrevs under det gånga verksamhetsåret. Där brukar också anges hur många medlemmar klubben haft och vilka som suttit i styrelsen.'
      }
    ],

    verifications: [
      {
        title: 'Är registreringen OK enligt GDPR?',
        body:
          'Vi följer GDPR och har stöd av lagen vid inhämtande av medlemsuppgifter eftersom vi söker statsbidrag för verksamheten och måste då kunna redovisa våra medlemmar.'
      },
      {
        title: 'Vem ska skriva under medlemsintygen?',
        body:
          'Det går bra att eleverna skriver under själva. Även vårdnadshavare kan göra detta. Däremot är det inte tillåtet att skolpersonal skriver under intygen.'
      }
    ]
  }

  return (
    <>
      {(questions[page.join('.')] || []).map(({ title, body, expansionPanelProps }) => (
        <ExpansionPanel
          key={title}
          css={{ backgroundColor: 'transparent', border: '1px solid rgba(0,0,0,.12)', boxShadow: 'none' }}
          {...expansionPanelProps}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>{title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="body2">{body}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </>
  )
}
