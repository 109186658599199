import React from 'react'

export const Assistant: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 407 453" fill="#000000" {...props}>
    <path d="M307.277,289.826 C300.441,289.826 295.707,289.08 295.187,288.996 C293.008,288.639 291.531,286.584 291.887,284.406 L293.492,274.537 C293.664,273.488 294.242,272.551 295.106,271.932 C295.789,271.44 296.609,271.178 297.441,271.178 C297.656,271.178 297.875,271.197 298.09,271.232 C298.473,271.297 301.676,271.818 306.352,271.818 C315.035,271.818 327.426,270.084 337.125,261.82 C345.023,255.088 349.027,243.623 349.027,227.742 C349.027,226.621 349.496,225.552 350.324,224.794 C351.062,224.115 352.031,223.742 353.027,223.742 C353.141,223.742 353.258,223.747 353.371,223.757 L371.582,225.333 C373.648,225.513 375.238,227.243 375.238,229.318 C375.238,247.447 366.937,264.897 352.465,277.193 C337.606,289.826 317.121,289.826 307.277,289.826" />
    <path d="M350.965,176.293 C343.145,179.181 342.566,189.23 343.246,196.489 C340.91,195.717 338.387,195.219 335.887,195.554 C332.137,196.057 329.191,198.095 327.375,201.452 C322.406,210.607 331.23,216.639 335,219.215 L336.707,220.44 C338.246,221.778 338.617,222.845 339.801,226.703 L340.305,228.319 C342.523,235.405 353.023,237.076 354.215,237.244 C354.223,237.244 368.57,239.225 368.57,239.225 L368.316,239.182 C386.715,243.025 392.481,225.726 394.652,219.211 L395.422,217.047 C397.043,213.979 399.324,211.072 399.344,211.044 L399.004,211.419 C404.992,205.647 407.879,197.871 406.176,192.067 C405.078,188.329 402.184,185.73 398.223,184.944 C394.57,184.222 391.262,185.338 388.48,187.084 C388.484,185.612 388.406,184.213 388.168,182.966 C387.672,180.391 386.668,178.294 385.145,176.654 C382.453,173.748 378.305,172.655 373.461,173.581 C369.031,174.429 366.094,178.384 364.133,182.649 C363.453,181.406 362.773,180.285 362.121,179.518 C359.051,175.913 355.09,174.769 350.965,176.293" />
    <path
      d="M307.277,285.826 C300.785,285.826 296.316,285.127 295.836,285.049 L297.441,275.178 C297.719,275.225 322.441,279.588 339.719,264.865 C349,256.955 353.027,243.916 353.027,227.742 L371.238,229.318 C371.238,248.584 361.52,264.25 349.875,274.147 C336.625,285.408 318.613,285.826 307.277,285.826"
      fill="#F7E6C1"
    />
    <path
      d="M354.73,233.552 C354.73,233.552 345.441,232.245 343.859,227.206 C342.281,222.167 341.863,219.986 339.152,217.628 C336.445,215.271 326.551,210.794 330.652,203.229 C334.754,195.668 345.312,200.425 348.141,203.378 C348.141,203.378 343.047,183.192 352.258,179.79 C361.469,176.384 364.398,194.933 364.398,194.933 C364.398,194.933 366.828,178.646 374.164,177.244 C381.5,175.842 387.996,179.732 383.051,197.939 C383.051,197.939 389.594,187.032 397.496,188.603 C405.395,190.173 404.395,201.047 396.418,208.734 C396.418,208.734 393.937,211.881 392.125,215.307 C390.316,218.735 387.055,239.285 369.082,235.533 L354.73,233.552"
      fill="#F7E6C1"
    />
    <path d="M351.348,202.972 C351.348,202.972 370.387,202.618 389.016,213.025 C389.016,213.025 372.453,197.293 351.348,202.972" />
    <path d="M343.746,200.519 C344.051,200.613 351.156,203.542 353.356,212.944 C353.356,212.944 355.996,211.696 355.187,209.138 C352,200.197 344.895,197.944 344.562,197.843 L343.746,200.519" />
    <polyline points="362.895 202.675 365.684 202.809 366.539 184.71 363.746 184.576 362.895 202.675" />
    <polyline points="378.305 206.835 380.895 207.897 387.977 190.629 385.387 189.568 378.305 206.835" />
    <path d="M354.223,210.268 C354.305,210.273 362.367,210.895 365.828,215.57 C367.352,217.633 367.762,220.262 367.043,223.381 C367.043,223.381 371.484,220.148 368.066,213.9 C364.656,207.659 354.797,207.502 354.418,207.476 L354.223,210.268" />
    <path d="M12.398,327.236 L12.293,328.115 C12.118,329.545 10.845,342.178 19.824,345.56 C22.572,346.603 25.298,346.471 27.709,345.197 C31.337,343.279 33.998,338.9 35.615,332.185 C36.538,328.338 37.009,317.42 32.654,313.256 C31.034,311.701 28.878,311.158 26.763,311.772 C21.581,313.26 15.32,322.553 12.87,326.482 L12.398,327.236" />
    <path
      d="M16.26,328.6 C16.26,328.6 14.939,339.447 21.237,341.822 C27.532,344.197 30.502,336.322 31.726,331.252 C32.947,326.178 32.141,314.389 27.872,315.615 C23.6,316.842 16.26,328.6 16.26,328.6"
      fill="#D3B898"
    />
    <path d="M0.898,330.143 C1.329,333.85 3.419,336.971 6.935,339.17 C13.894,343.51 21.737,336.557 26.441,329.162 C28.778,326.123 32.685,322.994 34.425,323.346 C35.565,323.576 36.457,323.967 37.139,324.397 C38.077,324.982 38.516,325.514 38.764,325.861 C39.102,334.002 43.143,339.928 48.284,343.139 C49.951,344.178 51.715,344.943 53.486,345.42 C59.048,346.928 64.022,345.436 66.151,341.611 C70.26,334.236 66.381,330.424 63.261,327.361 L61.228,325.279 C61.025,325.057 60.945,324.912 60.93,324.877 C60.923,324.35 62.04,322.631 62.7,321.603 C63.953,319.662 65.508,317.252 66.219,314.33 C67.395,309.459 64.03,305.83 60.025,303.33 C56.633,301.217 52.796,299.912 50.857,299.334 C31.516,290.822 15.024,307.447 7.956,314.596 C2.764,319.842 0.319,325.221 0.898,330.143" />
    <path d="M118.899,268.736 C125.371,270.932 129.616,273.154 130.08,273.4 C132.026,274.44 132.766,276.861 131.731,278.81 L127.036,287.639 C126.542,288.58 125.689,289.279 124.672,289.588 C123.866,289.838 123.007,289.818 122.218,289.549 C122.013,289.478 121.814,289.393 121.622,289.291 C121.277,289.107 118.415,287.584 113.986,286.08 C105.764,283.287 93.475,280.947 81.635,285.658 C71.99,289.49 64.517,299.06 59.412,314.1 C59.053,315.162 58.261,316.021 57.233,316.475 C56.315,316.877 55.281,316.924 54.333,316.6 C54.228,316.568 54.117,316.525 54.014,316.478 L37.276,309.131 C35.381,308.295 34.428,306.147 35.095,304.185 C40.925,287.018 54.395,273.162 72.048,266.166 C90.18,258.986 109.58,265.572 118.899,268.736" />
    <path
      d="M117.616,272.521 C123.764,274.607 127.767,276.701 128.199,276.935 L123.504,285.764 C123.257,285.627 101.251,273.553 80.16,281.943 C68.823,286.447 60.818,297.498 55.623,312.818 L38.886,305.471 C45.08,287.225 59.316,275.514 73.523,269.889 C89.69,263.482 106.879,268.877 117.616,272.521"
      fill="#F7E6C1"
    />
    <path
      d="M49.659,303.147 C49.659,303.147 63.796,307.303 62.327,313.389 C60.861,319.482 54.114,323.439 58.276,327.986 C62.443,332.533 65.876,333.893 62.659,339.67 C59.443,345.439 42.264,340.553 42.602,324.424 C42.602,324.424 40.743,320.541 35.218,319.424 C29.691,318.307 23.257,326.744 23.257,326.744 C23.257,326.744 15.215,339.619 9.054,335.775 C2.898,331.928 3.419,324.865 10.795,317.404 C18.172,309.955 33.033,295.494 49.659,303.147"
      fill="#F7E6C1"
    />
    <path d="M212.545,189.787 C160.454,189.787 118.074,147.408 118.074,95.316 C118.074,43.225 160.454,0.845 212.545,0.845 C264.637,0.845 307.016,43.225 307.016,95.316 C307.016,147.408 264.637,189.787 212.545,189.787" />
    <path
      d="M302.016,95.316 C302.016,144.729 261.959,184.787 212.545,184.787 C163.132,184.787 123.074,144.729 123.074,95.316 C123.074,45.902 163.132,5.845 212.545,5.845 C261.959,5.845 302.016,45.902 302.016,95.316"
      fill="#FFFCDD"
    />
    <path
      d="M212.545,123.064 C174.821,123.064 142.205,100.83 127.075,68.786 C124.476,77.169 123.074,86.079 123.074,95.316 C123.074,144.729 163.132,184.787 212.545,184.787 C261.959,184.787 302.016,144.729 302.016,95.316 C302.016,86.079 300.615,77.169 298.016,68.786 C282.887,100.83 250.27,123.064 212.545,123.064"
      fill="#F7E6C1"
    />
    <path d="M106.744,333.022 C105.195,333.022 103.734,332.305 102.788,331.08 C101.841,329.853 101.516,328.26 101.906,326.76 L142.982,169.198 C143.556,166.997 145.545,165.46 147.82,165.46 L277.27,165.46 C279.545,165.46 281.535,166.997 282.107,169.198 L323.184,326.76 C323.574,328.26 323.25,329.853 322.301,331.08 C321.355,332.305 319.895,333.022 318.346,333.022 L106.744,333.022" />
    <polyline fill="#FFFCDD" points="147.82 170.46 106.744 328.021 318.346 328.021 277.27 170.46 147.82 170.46" />
    <path
      d="M147.442,188.829 L277.648,188.829 C279.125,188.829 280.547,188.606 281.897,188.207 L277.27,170.46 L147.82,170.46 L143.193,188.207 C144.542,188.606 145.966,188.829 147.442,188.829"
      fill="#D3B898"
    />
    <path
      d="M299.863,257.123 C274.711,274.119 229.289,272.506 187.9,253.213 C163.106,241.652 144.049,238.174 130.195,238.068 L106.744,328.021 L318.346,328.021 L299.863,257.123"
      fill="#F7E6C1"
    />
    <path d="M147.442,182.817 C139.171,182.817 132.442,176.089 132.442,167.817 L132.442,166.973 C132.442,158.701 139.171,151.973 147.442,151.973 L277.648,151.973 C285.918,151.973 292.648,158.701 292.648,166.973 L292.648,167.817 C292.648,176.089 285.918,182.817 277.648,182.817 L147.442,182.817" />
    <path
      d="M287.648,167.817 C287.648,173.34 283.17,177.817 277.648,177.817 L147.442,177.817 C141.92,177.817 137.442,173.34 137.442,167.817 L137.442,166.973 C137.442,161.45 141.92,156.973 147.442,156.973 L277.648,156.973 C283.17,156.973 287.648,161.45 287.648,166.973 L287.648,167.817"
      fill="#F7E6C1"
    />
    <path
      d="M147.442,162.751 L277.648,162.751 C281.473,162.751 284.953,164.201 287.606,166.565 C287.387,161.235 283.031,156.973 277.648,156.973 L147.442,156.973 C142.06,156.973 137.702,161.235 137.483,166.565 C140.136,164.201 143.618,162.751 147.442,162.751"
      fill="#FFFCDD"
    />
    <path d="M78.696,452.518 C61.11,452.518 50.298,449.338 44.67,442.51 C40.464,437.408 39.283,430.479 41.062,421.328 L41.18,420.709 C49.256,378.185 72.415,353.799 104.72,353.799 L320.371,353.799 C352.676,353.799 375.834,378.185 383.91,420.709 L384.027,421.328 C385.807,430.479 384.625,437.408 380.42,442.51 C374.793,449.338 363.981,452.518 346.395,452.518 L78.696,452.518" />
    <path
      d="M104.72,358.799 C56.829,358.799 47.395,414.945 45.97,422.281 C42.777,438.715 49.612,447.518 78.696,447.518 L346.395,447.518 C375.477,447.518 382.312,438.715 379.121,422.281 C377.695,414.945 368.262,358.799 320.371,358.799 L104.72,358.799"
      fill="#F7E6C1"
    />
    <path
      d="M320.371,358.799 L104.72,358.799 C92.831,358.799 83.317,362.264 75.703,367.58 C79.848,371.565 86.308,371.584 91.522,371.584 L333.568,371.584 C338.781,371.584 345.242,371.565 349.387,367.58 C341.773,362.264 332.258,358.799 320.371,358.799"
      fill="#D3B898"
    />
    <path
      d="M378.18,417.59 C372.115,422.846 361.887,425.314 346.395,425.314 L78.696,425.314 C63.204,425.314 52.975,422.846 46.909,417.59 C46.44,419.752 46.144,421.385 45.97,422.281 C42.777,438.715 49.612,447.518 78.696,447.518 L346.395,447.518 C375.477,447.518 382.312,438.715 379.121,422.281 C378.945,421.385 378.648,419.752 378.18,417.59"
      fill="#D3B898"
    />
    <path d="M91.522,364.045 C86.231,364.045 79.648,364.045 75.513,359.883 C74.034,358.393 72.276,355.709 72.306,351.385 C72.385,339.592 88.963,316.6 102.963,316.6 L322.127,316.6 C336.127,316.6 352.705,339.592 352.785,351.385 C352.812,355.709 351.055,358.393 349.578,359.883 C345.441,364.045 338.859,364.045 333.568,364.045 L91.522,364.045" />
    <path
      d="M139.715,359.045 C139.715,359.045 98.213,359.045 91.522,359.045 C84.83,359.045 77.255,358.951 77.306,351.418 C77.369,341.984 92.215,321.6 102.963,321.6 C113.711,321.6 179.934,321.6 179.934,321.6 L245.156,321.6 C245.156,321.6 311.379,321.6 322.127,321.6 C332.875,321.6 347.721,341.984 347.785,351.418 C347.836,358.951 340.26,359.045 333.568,359.045 C326.877,359.045 285.375,359.045 285.375,359.045 L139.715,359.045"
      fill="#F7E6C1"
    />
    <path
      d="M102.963,328.519 L322.127,328.519 C331.121,328.519 341.174,338.01 347.238,347.924 C344.479,337.721 331.687,321.6 322.127,321.6 C311.379,321.6 245.156,321.6 245.156,321.6 L179.934,321.6 C179.934,321.6 113.711,321.6 102.963,321.6 C93.403,321.6 80.611,337.721 77.853,347.924 C83.917,338.01 93.969,328.519 102.963,328.519"
      fill="#FFFCDD"
    />
    <path d="M237.324,202.209 C225.012,202.209 214.996,189.07 214.996,172.922 C214.996,156.771 225.012,143.637 237.324,143.637 C249.637,143.637 259.652,156.771 259.652,172.922 C259.652,189.07 249.637,202.209 237.324,202.209" />
    <path
      d="M237.324,197.627 C226.668,197.627 217.996,186.135 217.996,172.014 C217.996,157.891 226.668,146.402 237.324,146.402 C247.98,146.402 256.652,157.891 256.652,172.014 C256.652,186.135 247.98,197.627 237.324,197.627"
      fill="#D6EEF5"
    />
    <path
      d="M237.324,151.527 C244.973,151.527 251.73,156.602 255.754,164.313 C253.281,153.941 245.957,146.402 237.324,146.402 C228.691,146.402 221.367,153.941 218.895,164.313 C222.918,156.602 229.676,151.527 237.324,151.527"
      fill="#FFFFFE"
    />
    <path d="M190.864,201.99 C175.985,201.99 163.88,186.861 163.88,168.268 C163.88,149.674 175.985,134.549 190.864,134.549 C205.743,134.549 217.848,149.674 217.848,168.268 C217.848,186.861 205.743,201.99 190.864,201.99" />
    <path
      d="M190.864,197.414 C177.638,197.414 166.88,183.984 166.88,167.478 C166.88,150.977 177.638,137.549 190.864,137.549 C204.091,137.549 214.848,150.977 214.848,167.478 C214.848,183.984 204.091,197.414 190.864,197.414"
      fill="#D6EEF5"
    />
    <path
      d="M190.864,143.254 C200.689,143.254 209.281,149.871 214,159.705 C211.246,146.965 201.931,137.549 190.864,137.549 C179.798,137.549 170.481,146.965 167.728,159.705 C172.446,149.871 181.04,143.254 190.864,143.254"
      fill="#FFFFFE"
    />
    <path d="M205.36,170.113 C205.36,171.812 203.981,173.19 202.282,173.19 C200.583,173.19 199.208,171.812 199.208,170.113 C199.208,168.414 200.583,167.037 202.282,167.037 C203.981,167.037 205.36,168.414 205.36,170.113" />
    <path d="M232.844,170.113 C232.844,171.812 231.469,173.19 229.77,173.19 C228.07,173.19 226.691,171.812 226.691,170.113 C226.691,168.414 228.07,167.037 229.77,167.037 C231.469,167.037 232.844,168.414 232.844,170.113" />
    <path d="M184.407,130.779 C184.407,130.779 191.485,124.625 207.177,125.855 C207.177,125.855 208.406,114.779 199.794,114.779 C191.177,114.779 184.099,122.473 184.407,130.779" />
    <path d="M230.563,132.316 C230.563,132.316 237.023,131.086 245.945,134.471 C245.945,134.471 248.715,122.779 237.945,121.855 C227.176,120.932 230.563,132.316 230.563,132.316" />
    <path d="M146.306,204.923 C146.306,204.923 147.407,198.765 155.325,197.444 C155.325,197.444 144.544,195.466 146.306,204.923" />
    <path d="M278.879,204.829 C278.879,204.829 277.777,198.671 269.859,197.351 C269.859,197.351 280.641,195.372 278.879,204.829" />
    <path d="M212.191,232.193 C161.56,232.193 147.681,200.427 147.548,200.106 L147.353,199.646 L150.118,198.482 L150.314,198.942 C150.442,199.244 163.751,229.193 212.191,229.193 L212.902,229.193 C261.344,229.193 274.652,199.244 274.781,198.942 L274.977,198.483 L277.742,199.646 L277.547,200.106 C277.414,200.427 263.535,232.193 212.902,232.193 L212.191,232.193" />
  </svg>
)
