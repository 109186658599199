// import { useQuery } from '@apollo/react-hooks';
// import { Card, CardContent, Container, Typography } from '@material-ui/core';
// import { Match as IMatch } from 'chessalpha-play/dist/models/Match';
// import gql from 'graphql-tag';
import React from 'react';
import { useUser } from '../../contexts';
// import { SimpleGame } from '..';

// const GET_MATCH = gql`
//   query ($matchId: Int!) {
//     match: getMemdbMatch(memdbId: $matchId) {
//       _id
//       memdbId
//       home
//       away
//       homeCaptains {
//         id
//         name
//       }
//       awayCaptains {
//         id
//         name
//       }
//       homePoints
//       awayPoints
//       games
//     }
//   }
// `;

// const MATCH_UPDATED = gql`
//   subscription ($_id: ObjectId!) {
//     matchUpdated(_id: $_id) {
//       _id
//       memdbId
//       home
//       away
//       homeCaptains {
//         id
//         name
//       }
//       awayCaptains {
//         id
//         name
//       }
//       homePoints
//       awayPoints
//       games
//     }
//   }
// `;

export const Match = ({ matchId }: { matchId: number }) => {
  // console.log(matchId)
  // const { data, subscribeToMore } = useQuery<{ match: IMatch }>(GET_MATCH, {
  //   variables: {
  //     matchId
  //   }
  // })

  // const match = data?.match

  // useEffect(() => {
  //   if (match?._id) {
  //     const unsubscribe = subscribeToMore({
  //       document: MATCH_UPDATED,
  //       variables: {
  //         _id: match._id
  //       },
  //       updateQuery: (prev, { subscriptionData }) => {
  //         console.log(prev, subscriptionData);
  //         return prev
  //       }
  //     })
  //     return unsubscribe()
  //   }
  // }, [match?._id, subscribeToMore])

  // console.log(match)

  // function handleLoad() {
  //   debugger
  // }

  const { token } = useUser()

  return matchId && (
    <iframe src={`https://beta.yes2chess.se/match/${matchId}?token=${token || ''}&memdbUrl=${encodeURIComponent(process.env.GATSBY_MEMDB_URL || '')}`} css={{
      width: '100%',
      height: 'calc(100vh - 66px)',
      marginTop: 26,
      border: 0,
    }} />
      // <Container maxWidth="xs">
      //   <SimpleGame />
      //   <Card css={{ marginTop: 100 }}>
      //     <CardContent>
      //       <Typography>Här kommer du kunna spela din match</Typography>
      //     </CardContent>
      //   </Card>
      // </Container>
  )
}
