import styled from '@emotion/styled'

export const ButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: '0 0 auto',
  '> button:not(:last-child)': {
    marginLeft: '8px'
  }
})
