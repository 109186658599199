import React from 'react'
import { CircularProgress } from '@material-ui/core'

export function Loading(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(255,255,255,.9)'
      }}
      {...props}
    >
      <CircularProgress
        css={{
          position: 'absolute',
          top: 'calc(50% - 20px)',
          left: 'calc(50% - 20px)'
        }}
      />
    </div>
  )
}
